import React, { useEffect, useState } from 'react'
// import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
// import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// 
// import { Field } from '#page/Auth/components'
// import * as requests from '#src/requests'
import { useRecoilValue } from 'recoil';
import {
  accountBalanceState,
} from '../state'


export default function AccountBalance(props) {
  // const { t } = useTranslation()
  // const theme = useTheme()

  const accountBalance = useRecoilValue(accountBalanceState)

  if (accountBalance === null) return null

  return (
    <Box
      elevation={0}
      sx={{
        py: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Your current balance: {accountBalance} tokens
        </Typography>

    </Box>
  )
}

