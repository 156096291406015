import React, { useState, useEffect, useMemo } from 'react';
import localforage from 'localforage'
import { Box, Container, Stack } from '@mui/material';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountEmailState,
  initEmailState,
  resetCodeState,
  loginCodeState,
  showAuthAwaiterState,
  accountState,
  accountBalanceState,
  tokenState,
  modalShowState,
  isSubscribedSelector,
} from '#state'

import * as requests from '#src/requests'
import { useWSAPI } from '#src/wsapi'

import Background from './Background';
import Footer from './Footer';
import Header from './Header';


export function Layout() {
  return (
    <>
      <Background />
      <Hooks />
      <Stack
        direction="column"
        spacing={2}
        sx={{
          minHeight: '100vh',
        }}>
        <Header />
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
        <Footer />
      </Stack>
    </>
  );
}

function Hooks() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()


  const [email, setEmail] = useRecoilState(accountEmailState)
  const [showAuthAwaiter, setShowAuthAwaiter] = useRecoilState(showAuthAwaiterState)
  const [token, setToken] = useRecoilState(tokenState)
  const [account, setAccount] = useRecoilState(accountState)
  const [initEmail, setInitEmail] = useRecoilState(initEmailState)
  const [resetCode, setResetCode] = useRecoilState(resetCodeState)
  const [loginCode, setLoginCode] = useRecoilState(loginCodeState)
  // 
  const [modalShow, setModalShow] = useRecoilState(modalShowState)
  const [accountBalance, setAccountBalance] = useRecoilState(accountBalanceState)
  const isSubscribed = useRecoilValue(isSubscribedSelector)


  useEffect(() => {
    if (!token) {
      let t = searchParams.get('token')

      const run = async () => {
        if (t) {
          console.log('token', t)
          try {
            setToken(t)
            await localforage.setItem('token', t)
          } catch (err) {
            console.log('save token error', err)
          }
        } else {
          try {
            t = await localforage.getItem('token')
            if (t) {
              console.log('localforage token', t)
              setToken(t)
            } else {
              if (location.pathname === '/') {
                console.log('localforage no token, redirect to /order')
                navigate('/order')
              }
            }
          } catch (err) {
            console.log('get token error', err)
            navigate('/account/login')
          }
        }
      }
      run()
    }
  }, [location])

  useEffect(() => {
    const run = async () => {
      const initEmail = searchParams.get('email')
      if (initEmail) {
        console.log('initEmail', initEmail)
        setEmail(initEmail)
      }

      const initResetCode = searchParams.get('resetCode')
      if (initResetCode) {
        console.log('resetCode', initResetCode)
        setResetCode(initResetCode)
      }

      const initLoginCode = searchParams.get('loginCode')
      if (initLoginCode) {
        console.log('loginCode', initLoginCode)
        setLoginCode(initLoginCode)
      }
    }
    run()
  }, [])

  // useEffect(() => {
  //   if (account !== null && location.pathname === '/') {
  //     if (account) navigate('/app/topup')
  //     else navigate('/app/topup')
  //   }
  // }, [location, account])

  useEffect(() => {
    const run = async () => {
      setShowAuthAwaiter(true)
      const accountInfo = await requests.getTokenInfo(token)
      console.log('accountInfo', accountInfo)
      if (accountInfo.success) {
        // const { id, email, paymentInfo } = accountInfo
        setAccountBalance(accountInfo.balance || 0)
        setEmail(accountInfo.email)
        setAccount(accountInfo)
        setShowAuthAwaiter(false)
      }
    }
    if (token && !account) {
      run()
    }

  }, [token, account]) // location



  useWSAPI()
}

export default Layout;
