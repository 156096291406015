export default {
  "languages": {
    "en": "Английский",
    "ru": "Русский",
    "de": "Немецкий",
    "es": "Испанский",
    "fr": "Французский",
    "ja": "Японский"
  },
  "hello": {
    "title": "Онлайн защита в пару кликов",
    "login": "Войти",
    "register": "Создать аккаунт Cropd"
  },
  "servers": {
    "search": "Поиск"
  },
  "drawer": {
    "vpn": "Соединение",
    "settings": "Настройки",
    "support": "Поддержка"
  },
  "theme": {
    "current": "Текущая тема",
    "system": "Системная",
    "dark": "Темная",
    "light": "Светлая"
  },
  "paywall": {
    "title": "CropdAI Премиум",
    "upgradeButton": "ПОДКЛЮЧИТЬ ПРЕМИУМ",
    "titleModal": "Подключить Премиум",
    "subtitleModal": "Доступ ко всем платным функциям",
    "subtitles": [
      "Абсолютно без рекламы",
      "Лучшая скорость соединения",
      "Продвинутое шифрование",
      "Доступ к разным странам",
      "Техническая поддержка",
      "Доступ ко всем нововведениям"
    ],
    "awaiterText": "Ожидание ответа сервера платежей",
    "subscribeButton": "ПОДПИСАТЬСЯ",
    "subscribeButtonNoTrial": "ПОДПИСАТЬСЯ",
    "trial": "3 дня бесплатно, затем",
    "noTrial": "Возобновляемая подписка",
    "terms": "Условия использования",
    "privacy": "Политика конфиденциальности",
    "restore": "Восстановить покупки"
  },
  "dialogs": {
    "wireguardInstall": {
      "title": "Установка WireGuard",
      "text": "CropdAI использует официальный клиент WireGuard® для защиты вашего соединения. Для продолжения нажмите кнопку загрузки и разрешите установку WireGuard.",
      "button": "Установить"
    },
    "serviceInstall": {
      "title": "Установка сервиса",
      "text": "CropdAI использует фоновую службу для настройки и контроля подключений. Для продолжения нажмите кнопку установки и разрешите запуск сервиса.",
      "button": "Установить и запустить"
    },
    "serviceRun": {
      "title": "Запуск сервиса",
      "text": "Фоновая служба CropdAI остановлена. Для продолжения нажмите кнопку запуска сервиса.",
      "button": "Запустить"
    },
    "serviceRemove": {
      "title": "Удаление сервиса CropdAI",
      "text": "CropdAI использует фоновую службу для настройки и контроля подключений. Нажмите кнопку ниже, чтобы остановить и удалить службу CropdAI.",
      "button": "Удалить службу"
    },
    "tunnelRemove": {
      "title": "Удалить туннель Wireguard",
      "text": "Туннель Wireguard осуществляет и управляет ВПН соединением. Нажмите кнопку ниже, чтобы остановить и удалить сервис Wireguard туннеля. Ваше ВПН соединение будет разорвано!",
      "button": "Удалить туннель"
    },
    "accountExitDialog": {
      "title": "Выход из аккаунта",
      "text": "Вы уверены, что хотите выйти из аккаунта Cropd?",
      "mainButton": "Выйти",
      "cancelButton": "Отмена"
    },
    "updateDialog": {
      "title": "Обновление CropdAI",
      "text": "Новая версия CropdAI готова к установке. Необходимо перезапустить приложение чтобы продолжить обновление.",
      "mainButton": "Перезапустить",
      "cancelButton": "Отложить"
    }
  },
  "vpn": {
    "title": "Выбранный сервер",
    "buttonConnect": "ПОДКЛЮЧИТЬСЯ",
    "buttonDisconnect": "ОТКЛЮЧИТЬСЯ",
    "freeBadge": "FREE",
    "premiumBannerLine1": "Три дня Премиума бесплатно",
    "premiumBannerLine2": "Совершенно без рекламы",
    "securedConnection": "Cоединение защищено",
    "insecureConnection": "Небезопасное соединение",
    "VPNStates": [
      "Отключен",
      "Подключение",
      "Подключен",
      "Отключение",
      "Ошибка"
    ]
  },
  "support": {
    "title": "Управление и Поддержка",
    "wgReinstall": "Установить WireGuard®",
    "wgReinstallDesc": "Диалог установки WireGuard",
    "wgReinstallButton": "Открыть",
    "wgReinstallButtonInstalled": "Установлен",
    "wgReinstallButtonNotInstalled": "Не Установлен",
    "svcReinstall": "Установить службу CropdAI",
    "svcReinstallDesc": "Диалог установки службы CropdAI",
    "svcReinstallButton": "Открыть",
    "svcReinstallButtonInstalled": "Установлен",
    "svcReinstallButtonNotInstalled": "Не Установлен",
    "svcRun": "Запустить службу CropdAI",
    "svcRunDesc": "Диалог запуска службы CropdAI",
    "svcRunButton": "Запустить",
    "svcRunButtonRunning": "Запущен",
    "svcRunButtonStopped": "Остановлен",
    "svcRemove": "Удалить службу CropdAI",
    "svcRemoveDesc": "Диалог удаления службы CropdAI",
    "svcRemoveButton": "Удалить",
    "wgTunnelRemove": "Удалить WireGuard Туннель",
    "wgTunnelRemoveDesc": "При неполадках с соединением",
    "wgTunnelRemoveButton": "Удалить",
    "wgTunnelRemoveButtonRunning": "Запущен",
    "wgTunnelRemoveButtonStopped": "Остановлен"
  },
  "settings": {
    "title": "Настройки",
    "language": "Язык",
    "theme": "Тема",
    "themeDesc": "Изменить оформление",
    "account": "Аккаунт Cropd",
    "accountControl": "Управление аккаунтом",
    "accountControlDesc": "Cтраница управления аккаунтом",
    "accountControlButton": "Открыть",
    "accountLogin": "Войти",
    "accountLogout": "Выйти",
    "accountRemove": "УДАЛИТЬ Аккаунт",
    "subsControl": "Управление подпиской",
    "startWithSystem": "Запускать автоматически",
    "startWithSystemDesc": "Запускать приложение при входе в систему",
    "startHidden": "Скрыть при запуске",
    "startHiddenDesc": "Не показывать окно при запуске",
    "tracking": "Отправлять анонимную статистику",
    "trackingDesc": "Это поможет нам улучшить приложение"
  },
  "account": {
    "title": "Cropd Account",
    "errors": {
      "invalid_password": "Неверный логин или пароль. Попробуйте снова",
      "invalid_code": "Неверный код. Попробуйте снова",
      "get_profile_error": "Неверный логин или пароль. Попробуйте снова",
      "emptyEmail": "Email не может быть пустым!",
      "incorrectEmail": "Неверный email адрес!",
      "emptyPass": "Пароль не может быть пустым!",
      "emptyCode": "Код не может быть пустым!"
    },
    "login": {
      "title": "Войти",
      "createPre": "Новый пользователь?",
      "createLink": "Зарегистрироваться",
      "emailLabel": "Email адрес",
      "emailPlaceholder": "john.doe@gmail.com",
      "passLabel": "Пароль",
      "passPlaceholder": "***************",
      "mainBtn": "Продолжить",
      "resetLink": "Забыли пароль?",
      "otcBtn": "Получить код входа на email"
    },
    "register": {
      "title": "Регистрация",
      "loginPre": "Уже зарегистрированы?",
      "loginLink": "Войти",
      "emailLabel": "Email адрес",
      "emailPlaceholder": "john.doe@gmail.com",
      "passLabel": "Пароль",
      "passPlaceholder": "***************",
      "mainBtn": "Продолжить"
    },
    "resetPass": {
      "title": "Сброс Пароля",
      "createPre": "Новый пользователь?",
      "createLink": "Зарегистрироваться",
      "emailLabel": "Email адрес",
      "emailPlaceholder": "john.doe@gmail.com",
      "mainBtn": "Отправить код",
      "resetLink": "Уже есть код?",
      "otcBtn": "Войти по коду"
    },
    "updatePass": {
      "title": "Обновление Пароля",
      "createPre": "Новый пользователь?",
      "createLink": "Зарегистрироваться",
      "codeLabel": "Код сброса",
      "codePlaceholder": "***************",
      "passLabel": "Новый Пароль",
      "passPlaceholder": "***************",
      "mainBtn": "Продолжить",
      "resetLink": "Не получили код сброса?",
      "otcBtn": "Войти по коду"
    },
    "loginSendCode": {
      "title": "Вход по коду",
      "createPre": "Новый пользователь?",
      "createLink": "Зарегистрироваться",
      "emailLabel": "Email адрес",
      "emailPlaceholder": "john.doe@gmail.com",
      "mainBtn": "Продолжить",
      "otcLink": "Уже есть код входа?",
      "loginBtn": "Войти по паролю"
    },
    "loginCheckCode": {
      "title": "Вход по коду",
      "createPre": "Новый пользователь?",
      "createLink": "Зарегистрироваться",
      "emailLabel": "Email адрес",
      "emailPlaceholder": "john.doe@gmail.com",
      "codeLabel": "Код",
      "codePlaceholder": "123456",
      "mainBtn": "Продолжить",
      "otcLink": "Не получили код входа?",
      "loginBtn": "Войти по паролю"
    },
    "remove": {
      "title": "Удалить аккаунт",
      "message": "Вы уверены, что хотите навсегда удалить учетную запись Cropd?\nОтменить удаление будет невозможно!\nВам может потребоваться отменить действующие подписки вручную.",
      "cancelBtn": "Отмена",
      "removeBtn": "Получить код удаления на email"
  }
  },
  "location": {
    "region": {
      "Dev": "Dev",
      "Auto": "Быстрое подключение",
      "Favorites": "Избранное",
      "Latest": "Последние",
      "Free": "Бесплатно",
      "World": "Мир",
      "Americas": "Америка",
      "Europe": "Европа",
      "Asia": "Азия",
      "AsiaPacific": "Азия и Тихий океан",
      "Australia": "Австралия"
    },
    "country": {
      "CA": "Канада",
      "MX": "Мексика",
      "SE": "Швеция",
      "US": "США",
      "NL": "Нидерланды",
      "DE": "Германия",
      "GB": "Великобритания",
      "FR": "Франция",
      "IN": "Индия",
      "KR": "Южная Корея",
      "SG": "Республика Сингапур",
      "JP": "Япония",
      "AU": "Австралия",
      "RU": "Россия",
      "FI": "Финляндия",
      "HK": "Гонконг",
      "CH": "Швейцария",
      "ES": "Испания",
      "PT": "Португалия",
      "MD": "Молдова",
      "IE": "Ирландия",
      "RO": "Румыния",
      "HU": "Венгрия",
      "CZ": "Чехия",
      "LV": "Латвия",
      "UA": "Украина",
      "IL": "Израиль",
      "TR": "Турция",
      "PL": "Польша",
      "BG": "Болгария",
      "KZ": "Казахстан",
      "RS": "Сербия",
      "SK": "Словакия",
      "IT": "Италия",
      "GR": "Греция"
    },
    "city": {
      "dev": "Dev MSK",
      "auto_fastest": "Лучший сервер",
      "auto_nearest": "Ближайшая страна",
      "fr_paris_free": "Париж",
      "us_dallas_free": "Даллас",
      "de_frankfurt_free": "Франкфурт",
      "us_ny_free": "Нью-Йорк",
      "ru_moscow_free": "Москва",
      "auto": "Авто",
      "ca_toronto": "Торонто",
      "se_stockholm": "Стокгольм",
      "mx_mexico": "Мехико",
      "us_atlanta": "Атланта",
      "us_chicago": "Чикаго",
      "us_dallas": "Даллас",
      "us_la": "Лос-Анджелес",
      "us_miami": "Майами",
      "us_ny": "Нью-Йорк",
      "us_sf": "Сан-Франциско",
      "us_seattle": "Сиэтл",
      "us_sv": "Кремниевая Долина",
      "nl_amsterdam": "Амстердам",
      "de_frankfurt": "Франкфурт",
      "gb_london": "Лондон",
      "fr_paris": "Париж",
      "in_bengaluru": "Бангалор",
      "kr_seoul": "Сеул",
      "sg_singapore": "Сингапур",
      "jp_tokio": "Токио",
      "au_sydney": "Сидней",
      "ru_moscow": "Москва",
      "gb_coventry": "Ковентри",
      "fi_helsinki": "Хельсинки",
      "us_secaucus": "Секаукус",
      "hk_hongkong": "Гонконг",
      "nl_meppel": "Меппель",
      "ch_zug": "Цуг",
      "es_madrid": "Мадрид",
      "pt_lisbon": "Лиссабон",
      "md_chisinau": "Кишинев",
      "ie_dublin": "Дублин",
      "ro_bucharest": "Бухарест",
      "hu_budapest": "Будапешт",
      "cz_veseli": "Весели",
      "lv_riga": "Рига",
      "ua_kyiv": "Киев",
      "il_tel_aviv": "Тель-Авив",
      "tr_izmir": "Измир",
      "pl_warsaw": "Варшава",
      "bg_sofia": "София",
      "kz_almaty": "Алматы",
      "rs_belgrade": "Белград",
      "sk_bratislava": "Братислава",
      "it_rome": "Рим",
      "gr_thessaloniki": "Салоники",
      "us_secaucus_free": "Секаукус",
      "ca_beauharnois": "Богарнуа",
      "us_ashburn": "Ашберн",
    }
  }
}