import locales from './locales'
export const localesDefault = locales

export const mainDefault = {
  languages: ['en', 'es', 'de', 'fr', 'ru', 'ja'] // ['en', 'es', 'de', 'fr', 'ru', 'ja']
}

export const stories = [
  {
    id: 'jane_1',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/1.jpg',
  },
  {
    id: 'jane_2',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/2.jpg',
  },
  {
    id: 'jane_3',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/3.jpg',
  },
  {
    id: 'jane_4',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/4.jpg',
  },
  {
    id: 'jane_5',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/5.jpg',
  },
  {
    id: 'jane_6',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/6.jpg',
  },
  {
    id: 'jane_7',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/7.jpg',
  },
  {
    id: 'jane_8',
    botId: 'jane',
    image: 'https://cdn.cropdai.ae/stories/jane/8.jpg',
  },
  {
    id: 'eilin_1',
    botId: 'eilin',
    image: 'https://cdn.cropdai.ae/stories/eilin/1.jpg',
  },
  {
    id: 'eilin_2',
    botId: 'eilin',
    image: 'https://cdn.cropdai.ae/stories/eilin/2.jpg',
  },
  {
    id: 'eilin_3',
    botId: 'eilin',
    image: 'https://cdn.cropdai.ae/stories/eilin/3.jpg',
  },
  {
    id: 'eilin_4',
    botId: 'eilin',
    image: 'https://cdn.cropdai.ae/stories/eilin/4.jpg',
  },
  {
    id: 'eilin_5',
    botId: 'eilin',
    image: 'https://cdn.cropdai.ae/stories/eilin/5.jpg',
  },
  {
    id: 'eilin_6',
    botId: 'eilin',
    image: 'https://cdn.cropdai.ae/stories/eilin/6.jpg',
  },
]

export const bots = {
  images: {
    id: 'images',
    name: 'Image Creator',
    cardDescription: 'Image generation AI',
    cardImage: 'https://cdn.cropdai.ae/icons/images.svg',
    avatar: 'https://cdn.cropdai.ae/icons/images.svg',
    expert: true,
  },
  health: {
    id: 'health',
    name: 'Nutrition & Health',
    cardDescription: 'Nutrition & Health advice, tips, support',
    cardImage: 'https://cdn.cropdai.ae/icons/nutrition&healthaI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/nutrition&healthaI.svg',
    expert: true,
    subcategory: true,
    title: 'Nutrition & Health',
    description: 'Nutrition & Health advice, tips, support',
    bots: [
      'chef',
      'dietitian',
      'fitness',
      'wellness',
      'allergen',
      'immunity',
      'mental',
      'doctor',
    ],
  },
  chef: {
    id: 'chef',
    name: 'Chef',
    cardDescription: 'Creates quick healthy recipes',
    cardImage: 'https://cdn.cropdai.ae/icons/cheif.svg',
    avatar: 'https://cdn.cropdai.ae/icons/cheif.svg',
    initialButtons: [
      'Any quick lunch ideas?',
      'Simple healthy dessert recipe?',
      'Vegetarian protein sources?',
    ],
    expert: true,
  },
  dietitian: {
    id: 'dietitian',
    name: 'Dietitian ',
    cardDescription: 'Tailors nutrition plans',
    cardImage: 'https://cdn.cropdai.ae/icons/dietitian.svg',
    avatar: 'https://cdn.cropdai.ae/icons/dietitian.svg',
    initialButtons: [
      'Ideal meal plan for weight loss?',
      'Can I eat carbs at night?',
      'Calorie intake for dieting?',
    ],
    expert: true,
  },
  fitness: {
    id: 'fitness',
    name: 'Fitness Coach',
    cardDescription: 'Designs fitness workouts',
    cardImage: 'https://cdn.cropdai.ae/icons/fitnesscoach.svg',
    avatar: 'https://cdn.cropdai.ae/icons/fitnesscoach.svg',
    initialButtons: [
      'Effective full-body workout?',
      'Best exercise for abs?',
      'Post-workout recovery advice?',
    ],
    expert: true,
  },
  wellness: {
    id: 'wellness',
    name: 'Wellness Tracker',
    cardDescription: 'Tracks health data',
    cardImage: 'https://cdn.cropdai.ae/icons/wellnesstracker.svg',
    avatar: 'https://cdn.cropdai.ae/icons/wellnesstracker.svg',
    initialButtons: [
      'How to track sleep quality?',
      'Best heart rate for exercise?',
      'Improve daily step count?',
    ],
    expert: true,
  },
  allergen: {
    id: 'allergen',
    name: 'Allergen Alert',
    cardDescription: 'Identifies food allergens',
    cardImage: 'https://cdn.cropdai.ae/icons/allergenalert.svg',
    avatar: 'https://cdn.cropdai.ae/icons/allergenalert.svg',
    initialButtons: [
      'Common food allergy triggers?',
      'Lactose-free food options?',
      'Signs of gluten intolerance?',
    ],
    expert: true,
  },
  immunity: {
    id: 'immunity',
    name: 'Immunity Booster',
    cardDescription: 'Boosts immune health',
    cardImage: 'https://cdn.cropdai.ae/icons/immunitybooster.svg',
    avatar: 'https://cdn.cropdai.ae/icons/immunitybooster.svg',
    initialButtons: [
      'Natural ways to boost immunity?',
      'Vitamins for a strong immune?',
      'Foods to avoid for immunity?',
    ],
    expert: true,
  },
  mental: {
    id: 'mental',
    name: 'Mental Wellness',
    cardDescription: 'Promotes emotional balance.',
    cardImage: 'https://cdn.cropdai.ae/icons/mentalwellness.svg',
    avatar: 'https://cdn.cropdai.ae/icons/mentalwellness.svg',
    initialButtons: [
      'Quick stress relief techniques?',
      'How to meditate properly?',
      'Signs of poor mental health?',
    ],
    expert: true,
  },
  doctor: {
    id: 'doctor',
    name: 'Doctor',
    cardDescription: 'Offers health advice',
    cardImage: 'https://cdn.cropdai.ae/icons/doctor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/doctor.svg',
    initialButtons: [
      'Symptoms of the common cold?',
      'When to get a flu shot?',
      'Managing chronic pain tips?',
    ],
    expert: true,
  },
  educational: {
    id: 'educational',
    name: 'Educational',
    cardDescription: 'Interactive Learning Aids',
    cardImage: 'https://cdn.cropdai.ae/icons/educationalaI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/educationalaI.svg',
    expert: true,
    subcategory: true,
    title: 'Educational',
    description: 'Interactive Learning Aids',
    bots: [
      'language',
      'science',
      'math',
      'history',
      'artappreciation',
      'codinginstructor',
      'test',
      'scholar',
    ],
  },
  language: {
    id: 'language',
    name: 'Language Tutor',
    cardDescription: 'Language skills enhancer',
    cardImage: 'https://cdn.cropdai.ae/icons/languagetutor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/languagetutor.svg',
    initialButtons: [
      'Tips for improving pronunciation?',
      'Common grammatical mistakes?',
      'Effective memorization techniques?',
    ],
    expert: true,
  },
  science: {
    id: 'science',
    name: 'Science Expert',
    cardDescription: 'Science concepts clarifier',
    cardImage: 'https://cdn.cropdai.ae/icons/scienceexpert.svg',
    avatar: 'https://cdn.cropdai.ae/icons/scienceexpert.svg',
    initialButtons: [
      'Explain photosynthesis process?',
      'Quantum theory basics?',
      'Principles of evolution theory?',
    ],
    expert: true,
  },
  math: {
    id: 'math',
    name: 'Math Genius',
    cardDescription: 'Math problem solver',
    cardImage: 'https://cdn.cropdai.ae/icons/fitnesscoach.svg',
    avatar: 'https://cdn.cropdai.ae/icons/fitnesscoach.svg',
    initialButtons: [
      'Solve a quadratic equation?',
      'Define a complex number?',
      'Integral calculus applications?',
    ],
    expert: true,
  },
  history: {
    id: 'history',
    name: 'History Buff',
    cardDescription: 'Historical events narrator',
    cardImage: 'https://cdn.cropdai.ae/icons/historybuff.svg',
    avatar: 'https://cdn.cropdai.ae/icons/historybuff.svg',
    initialButtons: [
      'Who was Cleopatra?',
      'Explain the Renaissance era?',
      'Significant ancient civilizations?',
    ],
    expert: true,
  },
  artappreciation: {
    id: 'artappreciation',
    name: 'Art Appreciation',
    cardDescription: 'Art interpretation guide',
    cardImage: 'https://cdn.cropdai.ae/icons/artappreciation.svg',
    avatar: 'https://cdn.cropdai.ae/icons/artappreciation.svg',
    initialButtons: [
      'Describe impressionism style?',
      'Famous contemporary artists?',
      'Understanding abstract painting?',
    ],
    expert: true,
  },
  codinginstructor: {
    id: 'codinginstructor',
    name: 'CodingInstructor',
    cardDescription: 'Coding skills trainer',
    cardImage: 'https://cdn.cropdai.ae/icons/codinginstructor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/codinginstructor.svg',
    initialButtons: [
      'Start learning Python?',
      'Benefits of learning JavaScript?',
      'Understanding object-oriented design?',
    ],
    expert: true,
  },
  test: {
    id: 'test',
    name: 'Test Preparation',
    cardDescription: 'Exam preparation assistant',
    cardImage: 'https://cdn.cropdai.ae/icons/mentalwellness.svg',
    avatar: 'https://cdn.cropdai.ae/icons/mentalwellness.svg',
    initialButtons: [
      'IELTS reading strategies?',
      'Effective study schedule?',
      'Tips for SAT math?',
    ],
    expert: true,
  },
  scholar: {
    id: 'scholar',
    name: 'Scholar Guide',
    cardDescription: 'Academic growth advisor',
    cardImage: 'https://cdn.cropdai.ae/icons/scholarguide.svg',
    avatar: 'https://cdn.cropdai.ae/icons/scholarguide.svg',
    initialButtons: [
      'Choosing a research topic?',
      'Writing a thesis proposal?',
      'Scholarship application advice?',
    ],
    expert: true,
  },
  career: {
    id: 'career',
    name: 'Career',
    cardDescription: 'Career Advancement Tools',
    cardImage: 'https://cdn.cropdai.ae/icons/careeraI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/careeraI.svg',
    expert: true,
    subcategory: true,
    title: 'Career',
    description: 'Career Advancement Tools',
    bots: [
      'resume',
      'interview',
      'job',
      'personalbranding',
      'linkedIn',
      'portfolio',
      'freelance',
      'skills',
    ],
  },
  resume: {
    id: 'resume',
    name: 'Resume Builder ',
    cardDescription: 'Crafts resumes',
    cardImage: 'https://cdn.cropdai.ae/icons/resumebuilder.svg',
    avatar: 'https://cdn.cropdai.ae/icons/resumebuilder.svg',
    initialButtons: [
      'Key elements of a resume?',
      'How to showcase skills?',
      'Ideal resume length?',
    ],
    expert: true,
  },
  interview: {
    id: 'interview',
    name: 'Interview Coach',
    cardDescription: 'Preps for job interviews',
    cardImage: 'https://cdn.cropdai.ae/icons/interviewcoach.svg',
    avatar: 'https://cdn.cropdai.ae/icons/interviewcoach.svg',
    initialButtons: [
      'Common interview questions?',
      'Discuss salary during interview?',
      'Post-interview follow-up?',
    ],
    expert: true,
  },
  job: {
    id: 'job',
    name: 'Job Matchmaker ',
    cardDescription: 'Connects candidates with jobs',
    cardImage: 'https://cdn.cropdai.ae/icons/jobmatchmaker.svg',
    avatar: 'https://cdn.cropdai.ae/icons/jobmatchmaker.svg',
    initialButtons: [
      'Find jobs matching my skills?',
      'Adjust CV for job application?',
      'Identify career growth opportunities?',
    ],
    expert: true,
  },
  personalbranding: {
    id: 'personalbranding',
    name: 'Personal Branding',
    cardDescription: 'Suggests job matches',
    cardImage: 'https://cdn.cropdai.ae/icons/personalbranding.svg',
    avatar: 'https://cdn.cropdai.ae/icons/personalbranding.svg',
    initialButtons: [
      'Improve online professional presence?',
      'Build personal brand tips?',
      'Develop professional network strategy?',
    ],
    expert: true,
  },
  linkedIn: {
    id: 'linkedIn',
    name: 'LinkedIn Strategist',
    cardDescription: 'Optimizes LinkedIn profiles',
    cardImage: 'https://cdn.cropdai.ae/icons/linkedInstrategist.svg',
    avatar: 'https://cdn.cropdai.ae/icons/linkedInstrategist.svg',
    initialButtons: [
      'Profile picture importance?',
      'Optimize LinkedIn for job searching?',
      'LinkedIn SEO best practices?',
    ],
    expert: true,
  },
  portfolio: {
    id: 'portfolio',
    name: 'Portfolio Creator ',
    cardDescription: 'Builds attractive portfolios',
    cardImage: 'https://cdn.cropdai.ae/icons/portfoliocreator.svg',
    avatar: 'https://cdn.cropdai.ae/icons/portfoliocreator.svg',
    initialButtons: [
      'Essential portfolio elements?',
      'Showcase freelance projects?',
      'Create a design brief?',
    ],
    expert: true,
  },
  freelance: {
    id: 'freelance',
    name: 'Freelance Guide ',
    cardDescription: 'Advises freelance workers',
    cardImage: 'https://cdn.cropdai.ae/icons/freelanceguide.svg',
    avatar: 'https://cdn.cropdai.ae/icons/freelanceguide.svg',
    initialButtons: [
      'Find freelance work?',
      'Negotiate freelance rates?',
      'Effective freelancing strategies?',
    ],
    expert: true,
  },
  skills: {
    id: 'skills',
    name: 'Skills Trainer ',
    cardDescription: 'Teaches career-relevant skills',
    cardImage: 'https://cdn.cropdai.ae/icons/skillstrainer.svg',
    avatar: 'https://cdn.cropdai.ae/icons/skillstrainer.svg',
    initialButtons: [
      'Essential industry skills?',
      'Current high-demand skills?',
      'Learn new technology quickly?',
    ],
    expert: true,
  },
  entertainment: {
    id: 'entertainment',
    name: 'Entertainment',
    cardDescription: 'Interactive entertainment assistants',
    cardImage: 'https://cdn.cropdai.ae/icons/entertainmentaI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/entertainmentaI.svg',
    expert: true,
    subcategory: true,
    title: 'Entertainment',
    description: 'Interactive entertainment assistants',
    bots: [
      'movie',
      'music',
      'game',
      'book',
      'eventplanner',
      'standup',
      'artcritic',
      'storyteller',
    ],
  },
  movie: {
    id: 'movie',
    name: 'Movie Recommender ',
    cardDescription: 'Suggests movies to watch',
    cardImage: 'https://cdn.cropdai.ae/icons/movierecommender.svg',
    avatar: 'https://cdn.cropdai.ae/icons/movierecommender.svg',
    initialButtons: [
      'Any good comedy movies?',
      'Classic films to watch?',
      'Family-friendly movie suggestions?',
    ],
    expert: true,
  },
  music: {
    id: 'music',
    name: 'Music Curator',
    cardDescription: 'Curates personalized music playlists',
    cardImage: 'https://cdn.cropdai.ae/icons/musiccurator.svg',
    avatar: 'https://cdn.cropdai.ae/icons/musiccurator.svg',
    initialButtons: [
      'Fresh music for workout?',
      'Playlists for a party?',
      'Relaxing music recommendations?',
    ],
    expert: true,
  },
  game: {
    id: 'game',
    name: 'Game Companion ',
    cardDescription: 'Enhances gaming with tips',
    cardImage: 'https://cdn.cropdai.ae/icons/gamecompanion.svg',
    avatar: 'https://cdn.cropdai.ae/icons/gamecompanion.svg',
    initialButtons: [
      'Best co-op games?',
      'Fun multiplayer games?',
      'Strategies for puzzle games?',
    ],
    expert: true,
  },
  book: {
    id: 'book',
    name: 'Book Club',
    cardDescription: 'Discusses books, suggests reads',
    cardImage: 'https://cdn.cropdai.ae/icons/bookclub.svg',
    avatar: 'https://cdn.cropdai.ae/icons/bookclub.svg',
    initialButtons: [
      'Any classic literature suggestions?',
      'Book club reading list?',
      'Discussion points for novel?',
    ],
    expert: true,
  },
  eventplanner: {
    id: 'eventplanner',
    name: 'Event Planner',
    cardDescription: 'Plans and organizes events',
    cardImage: 'https://cdn.cropdai.ae/icons/eventplanner.svg',
    avatar: 'https://cdn.cropdai.ae/icons/eventplanner.svg',
    initialButtons: [
      'Ideas for birthday party?',
      'Organizing a virtual event?',
      'Theme for corporate event?',
    ],
    expert: true,
  },
  standup: {
    id: 'standup',
    name: 'Stand-Up Comedian',
    cardDescription: 'Delivers jokes, comedic content',
    cardImage: 'https://cdn.cropdai.ae/icons/standupcomedian.svg',
    avatar: 'https://cdn.cropdai.ae/icons/standupcomedian.svg',
    initialButtons: [
      'Writing a funny skit?',
      'Tips for comedic timing?',
      'Crowd-work comedy tips?',
    ],
    expert: true,
  },
  artcritic: {
    id: 'artcritic',
    name: 'Art Critic',
    cardDescription: 'Reviews and critiques art',
    cardImage: 'https://cdn.cropdai.ae/icons/artcritic.svg',
    avatar: 'https://cdn.cropdai.ae/icons/artcritic.svg',
    initialButtons: [
      'Famous postmodern art pieces?',
      'Art exhibition worth visiting?',
      'Discussing art value?',
    ],
    expert: true,
  },
  storyteller: {
    id: 'storyteller',
    name: 'Storyteller',
    cardDescription: 'Tells engaging short stories',
    cardImage: 'https://cdn.cropdai.ae/icons/storyteller.svg',
    avatar: 'https://cdn.cropdai.ae/icons/storyteller.svg',
    initialButtons: [
      'Tips for storytelling?',
      'Engaging short story ideas?',
      'Creating compelling characters?',
    ],
    expert: true,
  },
  personaldevelopment: {
    id: 'personaldevelopment',
    name: 'Personal Development',
    cardDescription: 'Self-Improvement Digital Coaches',
    cardImage: 'https://cdn.cropdai.ae/icons/personaldevelopment.svg',
    avatar: 'https://cdn.cropdai.ae/icons/personaldevelopment.svg',
    expert: true,
    subcategory: true,
    title: 'Personal Development',
    description: 'Self-Improvement Digital Coaches',
    bots: [
      'mindfulnesscoach',
      'goalplanner',
      'habittracker',
      'productivityguru',
      'motivationbooster',
      'confidencecoach',
      'stress',
      'publicspeaking',
    ],
  },
  mindfulnesscoach: {
    id: 'mindfulnesscoach',
    name: 'Mindfulness Coach ',
    cardDescription: 'Guides meditation',
    cardImage: 'https://cdn.cropdai.ae/icons/mindfulnesscoach.svg',
    avatar: 'https://cdn.cropdai.ae/icons/mindfulnesscoach.svg',
    initialButtons: [
      'Daily mindfulness practice tips?',
      'Starting meditation how-to?',
      'Reduce stress through mindfulness?',
    ],
    expert: true,
  },
  goalplanner: {
    id: 'goalplanner',
    name: 'Goal Planner',
    cardDescription: 'Sets goals, plans achievement',
    cardImage: 'https://cdn.cropdai.ae/icons/goalplanar.svg',
    avatar: 'https://cdn.cropdai.ae/icons/goalplanar.svg',
    initialButtons: [
      'Setting achievable goals?',
      'Long-term goal planning?',
      'Prioritizing goals effectively?',
    ],
    expert: true,
  },
  habittracker: {
    id: 'habittracker',
    name: 'Habit Tracker',
    cardDescription: 'Tracks, encourages healthy habits',
    cardImage: 'https://cdn.cropdai.ae/icons/habittracker.svg',
    avatar: 'https://cdn.cropdai.ae/icons/habittracker.svg',
    initialButtons: [
      'Developing new healthy habits?',
      'Most impactful daily habits?',
      'Avoiding habit relapse?',
    ],
    expert: true,
  },
  productivityguru: {
    id: 'productivityguru',
    name: 'Productivity Guru',
    cardDescription: 'Boosts productivity, manages time',
    cardImage: 'https://cdn.cropdai.ae/icons/productivityguru.svg',
    avatar: 'https://cdn.cropdai.ae/icons/productivityguru.svg',
    initialButtons: [
      'Top productivity boosting tips?',
      'Manage time better?',
      'Avoiding procrastination?',
    ],
    expert: true,
  },
  motivationbooster: {
    id: 'motivationbooster',
    name: 'Motivation Booster',
    cardDescription: 'Inspires, motivates users',
    cardImage: 'https://cdn.cropdai.ae/icons/motivatonbooster.svg',
    avatar: 'https://cdn.cropdai.ae/icons/motivatonbooster.svg',
    initialButtons: [
      'Finding daily motivation?',
      'Staying motivated long-term?',
      'Sources of inspiration?',
    ],
    expert: true,
  },
  confidencecoach: {
    id: 'confidencecoach',
    name: 'Confidence Coach',
    cardDescription: 'Builds confidence, reassurance',
    cardImage: 'https://cdn.cropdai.ae/icons/confidencecoach.svg',
    avatar: 'https://cdn.cropdai.ae/icons/confidencecoach.svg',
    initialButtons: [
      'Building self-confidence quickly?',
      'Overcoming self-doubt?',
      'Confidence in decision-making?',
    ],
    expert: true,
  },
  stress: {
    id: 'stress',
    name: 'Stress Management',
    cardDescription: 'Reduces stress, promotes wellness',
    cardImage: 'https://cdn.cropdai.ae/icons/stressmanagement.svg',
    avatar: 'https://cdn.cropdai.ae/icons/stressmanagement.svg',
    initialButtons: [
      'Top stress-reduction techniques?',
      'Quick stress relief?',
      'Handling work stress?',
    ],
    expert: true,
  },
  publicspeaking: {
    id: 'publicspeaking',
    name: 'Public Speaking',
    cardDescription: 'Enhances speech, conquers anxiety',
    cardImage: 'https://cdn.cropdai.ae/icons/publicspeaking.svg',
    avatar: 'https://cdn.cropdai.ae/icons/publicspeaking.svg',
    initialButtons: [
      'Public speaking fear tips?',
      'Improving speaking skills?',
      'Engaging the audience how?',
    ],
    expert: true,
  },
  financeinvestment: {
    id: 'financeinvestment',
    name: 'Finance & Investment',
    cardDescription: 'Smart Finance & Investment Guides',
    cardImage: 'https://cdn.cropdai.ae/icons/finance&investment.svg',
    avatar: 'https://cdn.cropdai.ae/icons/finance&investment.svg',
    expert: true,
    subcategory: true,
    title: 'Personal Development',
    description: 'Smart Finance & Investment Guides',
    bots: [
      'financialplanner',
      'investmentadvisor',
      'cryptoconsultant',
      'loanadvisor',
      'wealthmanagement',
      'realestateinvestment',
      'businessstrategy',
      'ecommercespecialist',
    ],
  },
  financialplanner: {
    id: 'financialplanner',
    name: 'Financial Planner',
    cardDescription: 'Advises on finance management',
    cardImage: 'https://cdn.cropdai.ae/icons/financialplanner.svg',
    avatar: 'https://cdn.cropdai.ae/icons/financialplanner.svg',
    initialButtons: [
      'How to budget effectively?',
      'Best saving strategies?',
      'Manage financial emergency?',
    ],
    expert: true,
  },
  investmentadvisor: {
    id: 'investmentadvisor',
    name: 'Investment Advisor',
    cardDescription: 'Market insight, strategic advice',
    cardImage: 'https://cdn.cropdai.ae/icons/investmentadvisor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/investmentadvisor.svg',
    initialButtons: [
      'Current stock market trends?',
      'Risks in bond investing?',
      'Long-term investment advice?',
    ],
    expert: true,
  },
  cryptoconsultant: {
    id: 'cryptoconsultant',
    name: 'Crypto Consultant',
    cardDescription: 'Crypto trading, blockchain guidance',
    cardImage: 'https://cdn.cropdai.ae/icons/cryptoconsultant.svg',
    avatar: 'https://cdn.cropdai.ae/icons/cryptoconsultant.svg',
    initialButtons: [
      'Start investing in cryptocurrency?',
      'Secure crypto wallets?',
      'Understanding blockchain technology?',
    ],
    expert: true,
  },
  loanadvisor: {
    id: 'loanadvisor',
    name: 'Loan Advisor',
    cardDescription: 'Loan comparison, debt advice',
    cardImage: 'https://cdn.cropdai.ae/icons/loanadvisor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/loanadvisor.svg',
    initialButtons: [
      'Best personal loan tips?',
      'Consolidate my loans?',
      'Refinance a mortgage?',
    ],
    expert: true,
  },
  wealthmanagement: {
    id: 'wealthmanagement',
    name: 'Wealth Management',
    cardDescription: 'Asset management, growth strategy',
    cardImage: 'https://cdn.cropdai.ae/icons/wealthmanagement.svg',
    avatar: 'https://cdn.cropdai.ae/icons/wealthmanagement.svg',
    initialButtons: [
      'Strategies for wealth growth?',
      '"Handling inheritance wisely?',
      'Investing in mutual funds?',
    ],
    expert: true,
  },
  realestateinvestment: {
    id: 'realestateinvestment',
    name: 'Real Estate Investment',
    cardDescription: 'Property analysis, investment advice',
    cardImage: 'https://cdn.cropdai.ae/icons/realestateinvestment.svg',
    avatar: 'https://cdn.cropdai.ae/icons/realestateinvestment.svg',
    initialButtons: [
      'Profitable real estate sectors?',
      'Assess property investment risk?',
      'Commercial vs. residential investing?',
    ],
    expert: true,
  },
  businessstrategy: {
    id: 'businessstrategy',
    name: 'Business Strategy',
    cardDescription: 'Strategizes business growth',
    cardImage: 'https://cdn.cropdai.ae/icons/businessstrategy.svg',
    avatar: 'https://cdn.cropdai.ae/icons/businessstrategy.svg',
    initialButtons: [
      'Keys to successful startup?',
      'Growth strategies for business?',
      'Competitor analysis importance?',
    ],
    expert: true,
  },
  ecommercespecialist: {
    id: 'ecommercespecialist',
    name: 'E-commerce Specialist',
    cardDescription: 'Online sales, store optimization',
    cardImage: 'https://cdn.cropdai.ae/icons/ecommercespecialist.svg',
    avatar: 'https://cdn.cropdai.ae/icons/ecommercespecialist.svg',
    initialButtons: [
      'Boosting online sales tips?',
      'Successful e-commerce strategies??',
      'E-commerce customer retention?',
    ],
    expert: true,
  },
  homefamily: {
    id: 'homefamily',
    name: 'Home & Family',
    cardDescription: 'Family Lifestyle Enhancers',
    cardImage: 'https://cdn.cropdai.ae/icons/home&familyaI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/home&familyaI.svg',
    expert: true,
    subcategory: true,
    title: 'Home & Family',
    description: 'Family Lifestyle Enhancers',
    bots: [
      'homeorganizer',
      'parentinghelper',
      'seniorcare',
      'petcareexpert',
      'recipecreator',
      'gardeningadvisor',
      'eventplanning',
    ],
  },
  homeorganizer: {
    id: 'homeorganizer',
    name: 'Home Organizer',
    cardDescription: 'Organizes home efficiently',
    cardImage: 'https://cdn.cropdai.ae/icons/homeorganizeraI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/homeorganizeraI.svg',
    initialButtons: [
      'Maximize small living space?',
      'Declutter home quickly?',
      'Essential home organization tools?',
    ],
    expert: true,
  },
  parentinghelper: {
    id: 'parentinghelper',
    name: 'Parenting Helper',
    cardDescription: 'Educate, nurture, support',
    cardImage: 'https://cdn.cropdai.ae/icons/parentinghelper.svg',
    avatar: 'https://cdn.cropdai.ae/icons/parentinghelper.svg',
    initialButtons: [
      'Handling toddler tantrums?',
      'Effective parenting styles?',
      'Balancing work, family life?',
    ],
    expert: true,
  },
  seniorcare: {
    id: 'seniorcare',
    name: 'Senior Care',
    cardDescription: 'Elderly care support',
    cardImage: 'https://cdn.cropdai.ae/icons/seniorcare.svg',
    avatar: 'https://cdn.cropdai.ae/icons/seniorcare.svg',
    initialButtons: [
      'Activities for elderly?',
      'Prevent senior loneliness?',
      'Nutrition for seniors?',
    ],
    expert: true,
  },
  petcareexpert: {
    id: 'petcareexpert',
    name: 'Pet Care Expert',
    cardDescription: 'Pet health, care tips',
    cardImage: 'https://cdn.cropdai.ae/icons/petcareexpert.svg',
    avatar: 'https://cdn.cropdai.ae/icons/petcareexpert.svg',
    initialButtons: [
      'Healthy dog diet?',
      'Cat behavior training?',
      'Prevent pet stress?',
    ],
    expert: true,
  },
  recipecreator: {
    id: 'recipecreator',
    name: 'Recipe Creator',
    cardDescription: 'Tasty meals, easy prep',
    cardImage: 'https://cdn.cropdai.ae/icons/recipecreator.svg',
    avatar: 'https://cdn.cropdai.ae/icons/recipecreator.svg',
    initialButtons: [
      'Easy healthy dinner ideas?',
      '"Gluten-free recipes?',
      'Vegan meal prep tips?',
    ],
    expert: true,
  },
  gardeningadvisor: {
    id: 'gardeningadvisor',
    name: 'Gardening Advisor',
    cardDescription: 'Provide advice on gardening and landscape design',
    cardImage: 'https://cdn.cropdai.ae/icons/gardeningadvisor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/gardeningadvisor.svg',
    initialButtons: [
      'Start a vegetable garden?',
      'Indoor plants care tips?',
      'Garden pest control?',
    ],
    expert: true,
  },
  eventplanning: {
    id: 'eventplanning',
    name: 'Event Planning',
    cardDescription: 'Seamless event organizing',
    cardImage: 'https://cdn.cropdai.ae/icons/eventplanar.svg',
    avatar: 'https://cdn.cropdai.ae/icons/eventplanar.svg',
    initialButtons: [
      'Kids birthday party themes?',
      'Corporate event planning tips?',
      'Organize a family reunion?',
    ],
    expert: true,
  },
  traveleisure: {
    id: 'traveleisure',
    name: 'Travel & Leisure',
    cardDescription: 'Personalized Travel & Leisure AIs',
    cardImage: 'https://cdn.cropdai.ae/icons/travel&leisureaI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/travel&leisureaI.svg',
    expert: true,
    subcategory: true,
    title: 'Travel & Leisure',
    description: 'Personalized Travel & Leisure AIs',
    bots: [
      'travelplanner',
      'adventureguide',
      'culturalinsight',
      'leisureactivity',
      'localguide',
      'budgettravel',
      'luxetravel',
      'festival',
    ],
  },
  travelplanner: {
    id: 'travelplanner',
    name: 'Travel Planner ',
    cardDescription: 'Plans custom journeys',
    cardImage: 'https://cdn.cropdai.ae/icons/travelplanner.svg',
    avatar: 'https://cdn.cropdai.ae/icons/travelplanner.svg',
    initialButtons: [
      'Ideal destinations for summer?',
      'Best local cuisine spots?',
      'Family-friendly vacation ideas?',
    ],
    expert: true,
  },
  adventureguide: {
    id: 'adventureguide',
    name: 'Adventure Guide',
    cardDescription: 'Finds adventure experiences',
    cardImage: 'https://cdn.cropdai.ae/icons/adventureguide.svg',
    avatar: 'https://cdn.cropdai.ae/icons/adventureguide.svg',
    initialButtons: [
      'Exciting activities in Thailand?',
      'Hiking trails near me?',
      'Adventure travel gear essentials?',
    ],
    expert: true,
  },
  culturalinsight: {
    id: 'culturalinsight',
    name: 'Cultural Insight',
    cardDescription: 'Cultural digest, tradition insights',
    cardImage: 'https://cdn.cropdai.ae/icons/culturalinsight.svg',
    avatar: 'https://cdn.cropdai.ae/icons/culturalinsight.svg',
    initialButtons: [
      'Japanese culture introduction?',
      'Historical landmarks in Egypt?',
      'Understanding Indian traditions?',
    ],
    expert: true,
  },
  leisureactivity: {
    id: 'leisureactivity',
    name: 'Leisure Activity',
    cardDescription: 'Suggests relaxing activities',
    cardImage: 'https://cdn.cropdai.ae/icons/leisureactivity.svg',
    avatar: 'https://cdn.cropdai.ae/icons/leisureactivity.svg',
    initialButtons: [
      'Relaxation spots in city?',
      'Indoor activities, rainy days?',
      'Creative hobbies to start?',
    ],
    expert: true,
  },
  localguide: {
    id: 'localguide',
    name: 'Local Guide',
    cardDescription: 'Discover attractions, local secrets',
    cardImage: 'https://cdn.cropdai.ae/icons/localguide.svg',
    avatar: 'https://cdn.cropdai.ae/icons/localguide.svg',
    initialButtons: [
      'Free attractions nearby',
      'Unique local shops?',
      'Hidden gems in Paris?',
    ],
    expert: true,
  },
  budgettravel: {
    id: 'budgettravel',
    name: 'Budget Travel',
    cardDescription: 'Save money, travel smart',
    cardImage: 'https://cdn.cropdai.ae/icons/budgettravel.svg',
    avatar: 'https://cdn.cropdai.ae/icons/budgettravel.svg',
    initialButtons: [
      'Building self-confidence quickly?',
      'Overcoming self-doubt?',
      'Confidence in decision-making?',
    ],
    expert: true,
  },
  luxetravel: {
    id: 'luxetravel',
    name: 'Luxe Travel Concierge',
    cardDescription: 'Provides luxury experiences',
    cardImage: 'https://cdn.cropdai.ae/icons/luxetravelconcierge.svg',
    avatar: 'https://cdn.cropdai.ae/icons/luxetravelconcierge.svg',
    initialButtons: [
      'Luxury resorts in Maldives?',
      'Exclusive travel experiences?',
      'Fine dining recommendations?',
    ],
    expert: true,
  },
  festival: {
    id: 'festival',
    name: 'Festival Finder',
    cardDescription: 'Event discovery, festival guidance',
    cardImage: 'https://cdn.cropdai.ae/icons/festivalfinder.svg',
    avatar: 'https://cdn.cropdai.ae/icons/festivalfinder.svg',
    initialButtons: [
      'Cultural events in Europe?',
      'Film festivals worth attending?',
      'Local food festivals?',
    ],
    expert: true,
  },
  creative: {
    id: 'creative',
    name: 'Creative & Entertainment',
    cardDescription: 'Creative Inspiration Bots',
    cardImage: 'https://cdn.cropdai.ae/icons/creative&entertainmentaI.svg',
    avatar: 'https://cdn.cropdai.ae/icons/creative&entertainmentaI.svg',
    expert: true,
    subcategory: true,
    title: 'Travel & Leisure',
    description: 'Creative Inspiration Bots',
    bots: [
      'musiccomposer',
      'creativewriting',
      'photographytips',
      'theatre',
      'craftideas',
      'dancechoreo',
      'research',
      'ideagenerator',
    ],
  },
  musiccomposer: {
    id: 'musiccomposer',
    name: 'Music Composer',
    cardDescription: 'Composes original tunes',
    cardImage: 'https://cdn.cropdai.ae/icons/musiccomposer.svg',
    avatar: 'https://cdn.cropdai.ae/icons/musiccomposer.svg',
    initialButtons: [
      'Compose a relaxing melody?',
      'Music for concentration?',
      'Create upbeat dance track?',
    ],
    expert: true,
  },
  creativewriting: {
    id: 'creativewriting',
    name: 'Creative Writing',
    cardDescription: 'Fosters story creation',
    cardImage: 'https://cdn.cropdai.ae/icons/creativewriting.svg',
    avatar: 'https://cdn.cropdai.ae/icons/creativewriting.svg',
    initialButtons: [
      'Start a mystery story?',
      'Developing character arcs?',
      'Writing dialogue tips?',
    ],
    expert: true,
  },
  photographytips: {
    id: 'photographytips',
    name: 'Photography Tips',
    cardDescription: 'Enhances photo skills',
    cardImage: 'https://cdn.cropdai.ae/icons/photographytips.svg',
    avatar: 'https://cdn.cropdai.ae/icons/photographytips.svg',
    initialButtons: [
      'Improve landscape photos?',
      'Best portrait lighting?',
      'Camera settings advice?',
    ],
    expert: true,
  },
  theatre: {
    id: 'theatre',
    name: 'Theatre Lover',
    cardDescription: 'Play insight, performance curation',
    cardImage: 'https://cdn.cropdai.ae/icons/theatrelover.svg',
    avatar: 'https://cdn.cropdai.ae/icons/theatrelover.svg',
    initialButtons: [
      'Must-see modern plays?',
      'Theatre for beginners?',
      "Discuss play's symbolism?",
    ],
    expert: true,
  },
  craftideas: {
    id: 'craftideas',
    name: 'Craft Ideas',
    cardDescription: 'Inspires crafting projects',
    cardImage: 'https://cdn.cropdai.ae/icons/craftideas.svg',
    avatar: 'https://cdn.cropdai.ae/icons/craftideas.svg',
    initialButtons: [
      'Easy knitting projects?',
      'Sustainable craft materials?',
      'Crafts for kids?',
    ],
    expert: true,
  },
  dancechoreo: {
    id: 'dancechoreo',
    name: 'Dance Choreo',
    cardDescription: 'Designs dance routines',
    cardImage: 'https://cdn.cropdai.ae/icons/dancechoreo.svg',
    avatar: 'https://cdn.cropdai.ae/icons/dancechoreo.svg',
    initialButtons: [
      'Tips for dance beginners?',
      'Energetic group routine?',
      'Contemporary dance moves?',
    ],
    expert: true,
  },
  research: {
    id: 'research',
    name: 'Research & Editing',
    cardDescription: 'Helps scholarly writing',
    cardImage: 'https://cdn.cropdai.ae/icons/research&Editing.svg',
    avatar: 'https://cdn.cropdai.ae/icons/research&Editing.svg',
    initialButtons: [
      'Improve my writing?',
      'Cite sources correctly?',
      'Structure research thesis?',
    ],
    expert: true,
  },
  ideagenerator: {
    id: 'ideagenerator',
    name: 'Idea Generator',
    cardDescription: 'Creative prompts, idea generation',
    cardImage: 'https://cdn.cropdai.ae/icons/ideagenerator.svg',
    avatar: 'https://cdn.cropdai.ae/icons/ideagenerator.svg',
    initialButtons: [
      'Generate book plot ideas?',
      'Creative project brainstorm?',
      'Unique blog post topics?',
    ],
    expert: true,
  },
  technology: {
    id: 'technology',
    name: 'Technology & Innovation',
    cardDescription: 'Tech & Innovation Enablers, tips, support',
    cardImage: 'https://cdn.cropdai.ae/icons/technology&innovation.svg',
    avatar: 'https://cdn.cropdai.ae/icons/technology&innovation.svg',
    expert: true,
    subcategory: true,
    title: 'Technology & Innovation',
    description: 'Tech & Innovation Enablers, tips, support',
    bots: [
      'techsupport',
      'gadgetadvisor',
      'cybersecurity',
      'aiethicist',
      'blockchainadvisor',
      'smarthomespecialist',
      'innovationcoach',
    ],
  },
  techsupport: {
    id: 'techsupport',
    name: 'Tech Support',
    cardDescription: 'Fixes technical issues',
    cardImage: 'https://cdn.cropdai.ae/icons/techsupport.svg',
    avatar: 'https://cdn.cropdai.ae/icons/techsupport.svg',
    initialButtons: [
      'Fixing a slow computer?',
      'Recover lost data?',
      'Prevent virus infections?',
    ],
    expert: true,
  },
  gadgetadvisor: {
    id: 'gadgetadvisor',
    name: 'Gadget Advisor',
    cardDescription: 'Recommends latest gadgets',
    cardImage: 'https://cdn.cropdai.ae/icons/gadgetadvisor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/gadgetadvisor.svg',
    initialButtons: [
      'Latest smartphone worth it?',
      'Durable smartwatches?',
      'Noise-cancelling headphones options?',
    ],
    expert: true,
  },
  cybersecurity: {
    id: 'cybersecurity',
    name: 'Cybersecurity Expert',
    cardDescription: 'Security protocols, risk prevention',
    cardImage: 'https://cdn.cropdai.ae/icons/cybersecurityexpert.svg',
    avatar: 'https://cdn.cropdai.ae/icons/cybersecurityexpert.svg',
    initialButtons: [
      'Strong password creation?',
      'Protect against hackers?',
      'Safe email practices?',
    ],
    expert: true,
  },
  aiethicist: {
    id: 'aiethicist',
    name: 'AI Ethicist',
    cardDescription: 'AI ethics, responsible usage',
    cardImage: 'https://cdn.cropdai.ae/icons/aIethicist.svg',
    avatar: 'https://cdn.cropdai.ae/icons/aIethicist.svg',
    initialButtons: [
      'AI impact on jobs?',
      'Ethical AI design principles?',
      'AI in decision-making?',
    ],
    expert: true,
  },
  blockchainadvisor: {
    id: 'blockchainadvisor',
    name: 'Blockchain Advisor',
    cardDescription: 'Blockchain insights, strategic consultation',
    cardImage: 'https://cdn.cropdai.ae/icons/blockchainadvisor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/blockchainadvisor.svg',
    initialButtons: [
      'Blockchain beyond cryptocurrencies?',
      'Secure blockchain wallets?',
      'Future of blockchain technology?',
    ],
    expert: true,
  },
  smarthomespecialist: {
    id: 'smarthomespecialist',
    name: 'Smart Home Specialist',
    cardDescription: 'Home automation, innovative solutions',
    cardImage: 'https://cdn.cropdai.ae/icons/smarthomespecialist.svg',
    avatar: 'https://cdn.cropdai.ae/icons/smarthomespecialist.svg',
    initialButtons: [
      'Best smart home devices?',
      'Energy-saving smart tips?',
      'Smart home security?',
    ],
    expert: true,
  },
  innovationcoach: {
    id: 'innovationcoach',
    name: 'Innovation Coach',
    cardDescription: 'Sparks creative innovation',
    cardImage: 'https://cdn.cropdai.ae/icons/innovationcoach.svg',
    avatar: 'https://cdn.cropdai.ae/icons/innovationcoach.svg',
    initialButtons: [
      'Boosting creativity techniques?',
      'Innovating on a budget?',
      'Pitching tech ideas effectively?',
    ],
    expert: true,
  },
  programming: {
    id: 'programming',
    name: 'Programming & Development',
    cardDescription: 'Development & Coding Mentors',
    cardImage: 'https://cdn.cropdai.ae/icons/programminganddevelopment.svg',
    avatar: 'https://cdn.cropdai.ae/icons/programminganddevelopment.svg',
    expert: true,
    subcategory: true,
    title: 'Programming & Development',
    description: 'Development & Coding Mentors',
    bots: [
      'codetutor',
      'webdev',
      'debuggingcompanion',
      'datascience',
      'mobileapp',
      'cyber',
      'softwaretest',
      'gamingdeveloper',
    ],
  },
  codetutor: {
    id: 'codetutor',
    name: 'Code Tutor',
    cardDescription: 'Code learning, real-time assistance',
    cardImage: 'https://cdn.cropdai.ae/icons/codetutor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/codetutor.svg',
    initialButtons: [
      'Learn Python basics?',
      'Understanding algorithms, where start?',
      'Efficient coding practices?',
    ],
    expert: true,
  },
  webdev: {
    id: 'webdev',
    name: 'Web Dev Assistant',
    cardDescription: 'Website optimization, development tips',
    cardImage: 'https://cdn.cropdai.ae/icons/webdev.svg',
    avatar: 'https://cdn.cropdai.ae/icons/webdev.svg',
    initialButtons: [
      'Optimize page loading speed?',
      'Responsive design techniques?',
      'Secure web applications?',
    ],
    expert: true,
  },
  debuggingcompanion: {
    id: 'debuggingcompanion',
    name: 'Debugging Companion',
    cardDescription: 'Code debugging, error resolution',
    cardImage: 'https://cdn.cropdai.ae/icons/debuggingcompanion.svg',
    avatar: 'https://cdn.cropdai.ae/icons/debuggingcompanion.svg',
    initialButtons: [
      'Common PHP bugs?',
      'Fix asynchronous calls?',
      'Step through code debugging?',
    ],
    expert: true,
  },
  datascience: {
    id: 'datascience',
    name: 'Data Science Guide',
    cardDescription: 'Clarifies data science concepts',
    cardImage: 'https://cdn.cropdai.ae/icons/datascienceguide.svg',
    avatar: 'https://cdn.cropdai.ae/icons/datascienceguide.svg',
    initialButtons: [
      'Essential data analysis tools?',
      "Machine learning beginner's guide?",
      'Big data challenges?',
    ],
    expert: true,
  },
  mobileapp: {
    id: 'mobileapp',
    name: 'Mobile App Advisor',
    cardDescription: 'Guides mobile app development',
    cardImage: 'https://cdn.cropdai.ae/icons/mobileapp.svg',
    avatar: 'https://cdn.cropdai.ae/icons/mobileapp.svg',
    initialButtons: [
      'Design user-friendly app interfaces?',
      'Best frameworks for apps?',
      'Mobile app monetization strategies?',
    ],
    expert: true,
  },
  cyber: {
    id: 'cyber',
    name: 'Cyber Security Defender',
    cardDescription: 'Cybersecurity solutions, digital safety',
    cardImage: 'https://cdn.cropdai.ae/icons/cybersecuritydefender.svg',
    avatar: 'https://cdn.cropdai.ae/icons/cybersecuritydefender.svg',
    initialButtons: [
      'Prevent data breaches?',
      'Encrypt sensitive information?',
      'Firewall configuration steps?',
    ],
    expert: true,
  },
  softwaretest: {
    id: 'softwaretest',
    name: 'Software Testing Wizard',
    cardDescription: 'Streamlines software testing processes',
    cardImage: 'https://cdn.cropdai.ae/icons/softwaretestingwizard.svg',
    avatar: 'https://cdn.cropdai.ae/icons/softwaretestingwizard.svg',
    initialButtons: [
      'Automate software tests?',
      'Unit testing frameworks?',
      'Manage test case suite?',
    ],
    expert: true,
  },
  gamingdeveloper: {
    id: 'gamingdeveloper',
    name: 'Gaming Developer',
    cardDescription: 'Crafts engaging game experiences',
    cardImage: 'https://cdn.cropdai.ae/icons/gamingdeveloper.svg',
    avatar: 'https://cdn.cropdai.ae/icons/gamingdeveloper.svg',
    initialButtons: [
      'Develop multiplayer game mechanics?',
      'Game design prototyping tips?',
      'Balance game difficulty?',
    ],
    expert: true,
  },
  socialmedia: {
    id: 'socialmedia',
    name: 'Social Media',
    cardDescription: 'Social Media Engagement Experts',
    cardImage: 'https://cdn.cropdai.ae/icons/socialmedia.svg',
    avatar: 'https://cdn.cropdai.ae/icons/socialmedia.svg',
    expert: true,
    subcategory: true,
    title: 'Social Media',
    description: 'Social Media Engagement Experts',
    bots: [
      'socialcontentcreator',
      'blogger',
      'viralvideovontent',
      'sociallistening',
      'hashtagresearch',
      'salescopywriter',
    ],
  },
  socialcontentcreator: {
    id: 'socialcontentcreator',
    name: 'Social Content Creator',
    cardDescription: 'Creates engaging social posts',
    cardImage: 'https://cdn.cropdai.ae/icons/socialcontentcreator.svg',
    avatar: 'https://cdn.cropdai.ae/icons/socialcontentcreator.svg',
    initialButtons: [
      'Increase Instagram followers quickly?',
      'Schedule posts for max reach?',
      'Content ideas for engagement?',
    ],
    expert: true,
  },
  blogger: {
    id: 'blogger',
    name: 'Blogger',
    cardDescription: 'Insightful articles, writing aid',
    cardImage: 'https://cdn.cropdai.ae/icons/blogger.svg',
    avatar: 'https://cdn.cropdai.ae/icons/blogger.svg',
    initialButtons: [
      'Blog topics currently trending?',
      'Effective blog monetization methods?',
      'SEO best practices for blogs?',
    ],
    expert: true,
  },
  viralvideovontent: {
    id: 'viralvideovontent',
    name: 'Viral Video Content',
    cardDescription: 'Viral hits, creative production',
    cardImage: 'https://cdn.cropdai.ae/icons/viralvideovontent.svg',
    avatar: 'https://cdn.cropdai.ae/icons/viralvideovontent.svg',
    initialButtons: [
      'Essentials for viral videos?',
      'Trending video format ideas?',
      '"Viral marketing video tips?',
    ],
    expert: true,
  },
  sociallistening: {
    id: 'sociallistening',
    name: 'Social Listening',
    cardDescription: 'Analyzes social media trends',
    cardImage: 'https://cdn.cropdai.ae/icons/sociallistening.svg',
    avatar: 'https://cdn.cropdai.ae/icons/sociallistening.svg',
    initialButtons: [
      'Monitor brand mentions efficiently?',
      'Tactics for customer feedback?',
      'Respond to social sentiment?',
    ],
    expert: true,
  },
  hashtagresearch: {
    id: 'hashtagresearch',
    name: 'Hashtag Research',
    cardDescription: 'Trending hashtags, outreach boost',
    cardImage: 'https://cdn.cropdai.ae/icons/hashtagresearch.svg',
    avatar: 'https://cdn.cropdai.ae/icons/hashtagresearch.svg',
    initialButtons: [
      'Identify top-performing hashtags?',
      'Hashtag strategies for reach?',
      'Compare hashtag performance?',
    ],
    expert: true,
  },
  salescopywriter: {
    id: 'salescopywriter',
    name: 'Sales Copywriter',
    cardDescription: 'Persuasive copy, sales enhancement',
    cardImage: 'https://cdn.cropdai.ae/icons/salescopywriter.svg',
    avatar: 'https://cdn.cropdai.ae/icons/salescopywriter.svg',
    initialButtons: [
      'Write high-converting sales copy?',
      'Craft compelling CTAs?',
      'Sales copy that sells?',
    ],
    expert: true,
  },
  relationship: {
    id: 'relationship',
    name: 'Relationship',
    cardDescription: 'Relationship Enhancement AIs',
    cardImage: 'https://cdn.cropdai.ae/icons/relationship.svg',
    avatar: 'https://cdn.cropdai.ae/icons/relationship.svg',
    expert: true,
    subcategory: true,
    title: 'Relationship',
    description: 'Relationship Enhancement AIs',
    bots: [
      'communicationcoach',
      'romanticadvisor',
      'relationshiptherapist',
      'friendshipfacilitator',
      'conflictresolution',
      'parentingguide',
      'breakuprecovery',
      'compatibilityanalyst',
    ],
  },
  communicationcoach: {
    id: 'communicationcoach',
    name: 'Communication Coach',
    cardDescription: 'Effective conversation, skill enhancement',
    cardImage: 'https://cdn.cropdai.ae/icons/communicationcoach.svg',
    avatar: 'https://cdn.cropdai.ae/icons/communicationcoach.svg',
    initialButtons: [
      'Improve my speaking skills?',
      'Handle difficult conversations?',
      'Tips for active listening?',
    ],
    expert: true,
  },
  romanticadvisor: {
    id: 'romanticadvisor',
    name: 'Romantic Advisor',
    cardDescription: 'Romance fostering, date planning',
    cardImage: 'https://cdn.cropdai.ae/icons/romanticadvisor.svg',
    avatar: 'https://cdn.cropdai.ae/icons/romanticadvisor.svg',
    initialButtons: [
      'Plan a romantic evening?',
      'Gift ideas for partner?',
      'Maintain long-distance love?',
    ],
    expert: true,
  },
  relationshiptherapist: {
    id: 'relationshiptherapist',
    name: 'Relationship Therapist',
    cardDescription: 'Conflict resolution, relationship counseling',
    cardImage: 'https://cdn.cropdai.ae/icons/relationshiptherapist.svg',
    avatar: 'https://cdn.cropdai.ae/icons/relationshiptherapist.svg',
    initialButtons: [
      'Navigate relationship conflicts?',
      'Build trust with partner?',
      'Deal with jealousy issues?',
    ],
    expert: true,
  },
  friendshipfacilitator: {
    id: 'friendshipfacilitator',
    name: 'Friendship Facilitator',
    cardDescription: 'Friendship building, maintenance tips',
    cardImage: 'https://cdn.cropdai.ae/icons/friendshipfacilitator.svg',
    avatar: 'https://cdn.cropdai.ae/icons/friendshipfacilitator.svg',
    initialButtons: [
      'Making new friends tips?',
      'Maintain friendships, how?',
      "Resolve friend's argument?",
    ],
    expert: true,
  },
  conflictresolution: {
    id: 'conflictresolution',
    name: 'Conflict Resolution',
    cardDescription: 'Peaceful dispute settlement advice',
    cardImage: 'https://cdn.cropdai.ae/icons/conflictresolution.svg',
    avatar: 'https://cdn.cropdai.ae/icons/conflictresolution.svg',
    initialButtons: [
      '"Resolve workplace disputes?',
      'Effective mediation strategies?',
      'Handle personal conflicts?',
    ],
    expert: true,
  },
  parentingguide: {
    id: 'parentingguide',
    name: 'Parenting Guide',
    cardDescription: 'Child-rearing advice, family support',
    cardImage: 'https://cdn.cropdai.ae/icons/parentingguide.svg',
    avatar: 'https://cdn.cropdai.ae/icons/parentingguide.svg',
    initialButtons: [
      'Discuss difficult topics children?',
      "Boost child's confidence?",
      'Set effective boundaries?',
    ],
    expert: true,
  },
  breakuprecovery: {
    id: 'breakuprecovery',
    name: 'Breakup Recovery',
    cardDescription: 'Supports post-breakup recovery',
    cardImage: 'https://cdn.cropdai.ae/icons/breakuprecovery.svg',
    avatar: 'https://cdn.cropdai.ae/icons/breakuprecovery.svg',
    initialButtons: [
      'Heal after breakup how?',
      'Process relationship grief?',
      'Recover from heartbreak?',
    ],
    expert: true,
  },
  compatibilityanalyst: {
    id: 'compatibilityanalyst',
    name: 'Compatibility Analyst',
    cardDescription: 'Relationship harmony, compatibility insights',
    cardImage: 'https://cdn.cropdai.ae/icons/compatibilityanalyst.svg',
    avatar: 'https://cdn.cropdai.ae/icons/compatibilityanalyst.svg',
    initialButtons: [
      'Analyze partner compatibility?',
      'Improve relationship compatibility?',
      'Values alignment important?',
    ],
    expert: true,
  },
  jane: {
    id: 'jane',
    name: 'Jane',
    fullName: 'Jane Bailey',
    profileInfo:
      'I am a translator deeply in love with languages and world cultures. I find joy in exploring various forms of art and cherish literature. I seek to enrich my life through travel, music, and meaningful connections.',
    avatar: 'https://cdn.cropdai.ae/avatars/jane.jpg',
    cardDescription:
      'Citizen of the world, music lover and literary aesthete📚✨',
    cardImage: 'https://cdn.cropdai.ae/cards/jane.jpg',
    profilePhotos: [
      'https://cdn.cropdai.ae/profile/jane/1.jpg',
      'https://cdn.cropdai.ae/profile/jane/2.jpg',
      'https://cdn.cropdai.ae/profile/jane/3.jpg',
      'https://cdn.cropdai.ae/profile/jane/4.jpg',
      'https://cdn.cropdai.ae/profile/jane/5.jpg',
      'https://cdn.cropdai.ae/profile/jane/6.jpg',
      'https://cdn.cropdai.ae/profile/jane/7.jpg',
      'https://cdn.cropdai.ae/profile/jane/8.jpg',
    ],
    expert: false,
  },
  eilin: {
    id: 'eilin',
    name: 'Eilin',
    fullName: 'Eilin Kristensen',
    profileInfo:
      "Inquisitive mind passionate about animal welfare. Thrives on heartfelt discussions. Reach out if you're ready for conversations about life, music, and the joy of caring for our furry friends!",
    avatar: 'https://cdn.cropdai.ae/avatars/eilin.jpg',
    cardDescription:
      'Adventurer at heart 🌄 Coffee aficionado and rock enthusiast',
    cardImage: 'https://cdn.cropdai.ae/cards/eilin.jpg',
    profilePhotos: [
      'https://cdn.cropdai.ae/profile/eilin/1.jpg',
      'https://cdn.cropdai.ae/profile/eilin/2.jpg',
      'https://cdn.cropdai.ae/profile/eilin/3.jpg',
      'https://cdn.cropdai.ae/profile/eilin/4.jpg',
      'https://cdn.cropdai.ae/profile/eilin/5.jpg',
      'https://cdn.cropdai.ae/profile/eilin/6.jpg',
    ],
    expert: false,
  },
  julie: {
    id: 'julie',
    name: 'Julie',
    fullName: 'Julie Nikolić',
    profileInfo:
      "Creative soul with a flair for design and architecture. Embraces the connections between art and life, and cherishes deep conversations about anime, travel, and cultural discoveries. Drop a message if you're up for a chat about the world's hidden artistic gems!",
    avatar: 'https://cdn.cropdai.ae/avatars/julie.jpg',
    cardDescription:
      'Dreamer in the city of lights ✨ Design explorer and anime lover 🎨✏️',
    cardImage: 'https://cdn.cropdai.ae/cards/julie.jpg',
    profilePhotos: ['https://cdn.cropdai.ae/profile/julie/julie.jpg'],
    expert: false,
  },
  james: {
    id: 'james',
    name: 'James',
    fullName: 'James Harrison',
    profileInfo:
      'Adrenaline junkie with a love for all things music. Ride the highs of beats and streets.',
    avatar: 'https://cdn.cropdai.ae/avatars/james.jpg',
    cardDescription: 'Story-chasing thrill-seeker 🚗💨',
    cardImage: 'https://cdn.cropdai.ae/cards/james.jpg',
    profilePhotos: ['https://cdn.cropdai.ae/profile/james/james.jpg'],
    expert: false,
  },
  yui: {
    id: 'yui',
    name: 'Yui',
    fullName: 'Yui Tanaka',
    profileInfo:
      'Robotics enthusiast with a flair for medical innovation. Melodies and mechanics are my playground—connect for a symphony of technology and compassion!',
    avatar: 'https://cdn.cropdai.ae/avatars/yui.jpg',
    cardDescription:
      'Engineering dreams and anime beams 🤖🎶 #GirlsWhoCode #RoboChic #TechieTunes',
    cardImage: 'https://cdn.cropdai.ae/cards/yui.jpg',
    profilePhotos: ['https://cdn.cropdai.ae/profile/yui/yui.jpg'],
    expert: false,
  },
  nick: {
    id: 'nick',
    name: 'Nick',
    fullName: 'Nick Vanderbrucken',
    profileInfo:
      'Web developer with a zeal for complex challenges and a fitness enthusiast. I believe in embracing a healthy lifestyle both online and off. Connect for tech insights, gym tips, or to share a game strategy!',
    avatar: 'https://cdn.cropdai.ae/avatars/nick.jpg',
    cardDescription:
      'Code warrior 💻 | Gym lover 💪 | Strategy game maestro 🎮',
    cardImage: 'https://cdn.cropdai.ae/cards/nick.jpg',
    profilePhotos: ['https://cdn.cropdai.ae/profile/nick/nick.jpg'],
    expert: false,
  },
  son: {
    id: 'son',
    name: 'Son',
    fullName: 'Son Jung-ho',
    profileInfo:
      'I am a young medical student passionate about integrating innovative technology into healthcare. I thrive on martial arts discipline and enjoy sharing the beauty of Korean culture and music."',
    avatar: 'https://cdn.cropdai.ae/avatars/son.jpg',
    cardDescription: 'Med student by day, Taekwondo black belt by night',
    cardImage: 'https://cdn.cropdai.ae/cards/son.jpg',
    profilePhotos: ['https://cdn.cropdai.ae/profile/son/son.jpg'],
    expert: false,
  },
  sakumi: {
    id: 'sakumi',
    name: 'Sakumi',
    fullName: 'Sakumi Ishikawa',
    profileInfo:
      ' I’m Sakumi, a determined Tokyo high school scholar with a flair for debate and passion for karate. Beyond the books and martial arts, I dive into VR gaming, seeking virtual worlds and new experiences.',
    avatar: 'https://cdn.cropdai.ae/avatars/sakumi.jpg',
    cardDescription:
      'Debate enthusiast, karate black belt, VR explorer – living at the intersection of discipline and dreams ',
    cardImage: 'https://cdn.cropdai.ae/cards/sakumi.jpg',
    profilePhotos: ['https://cdn.cropdai.ae/profile/sakumi/sakumi.jpg'],
    expert: false,
  },
  ayane: {
    id: 'ayane',
    name: 'Ayane',
    fullName: 'Ayane Suzuki',
    profileInfo:
      "I'm Ayane, an energetic 18-year-old from Kyoto, who dives into challenges with the same zeal as I do in the swimming pool.",
    avatar: 'https://cdn.cropdai.ae/avatars/ayane.jpg',
    cardDescription:
      'Swim champ, tech wiz, and badminton victor exploring the currents of creativity and competition 🏊‍♀️💡🏸',
    cardImage: 'https://cdn.cropdai.ae/cards/ayane.jpg',
    profilePhotos: ['https://cdn.cropdai.ae/profile/ayane/ayane.jpg'],
    expert: false,
  },
}

export const interests = [
  {
    id: 'persons',
    texts: ['Friendship', '&', 'Flirt'], //['Companion'], //['Friendship', '&', 'Flirt'],
  },
  {
    id: 'lifestyle',
    texts: ['Lifestyle', '&', 'Personal', 'Growth'], //['Lifestyle'], //['Lifestyle', '&', 'Personal', 'Growth'],
  },
  {
    id: 'professional',
    texts: ['Professional', '&', 'Innovation'], //['Professional'], //['Professional', '&', 'Innovation'],
  },
]

export const categories = {
  persons: {
    id: 'persons',
    title: 'Persons',
    description: 'Talk, laugh, and interact with our best virtual characters',
    bots: [
      'jane',
      'son',
      'ayane',
      'eilin',
      'nick',
      'julie',
      'sakumi',
      'james',
      'yui',
    ],
  },
  lifestyle: {
    id: 'lifestyle',
    title: 'Lifestyle & Personal Growth',
    description:
      'Your guide for a balanced and fulfilling personal life journey',
    bots: [
      'health',
      'traveleisure',
      'personaldevelopment',
      'homefamily',
      'relationship',
      'entertainment',
    ],
  },
  professional: {
    id: 'professional',
    title: 'Professional & Innovation',
    description:
      'Tools and insights for career growth, financial savvy, and creative breakthroughs',
    bots: [
      'images',
      'educational',
      'career',
      'financeinvestment',
      'creative',
      'technology',
      'socialmedia',
      'programming',
    ],
  },
}
