export default {
  "languages": {
    "en": "Inglés",
    "ru": "Ruso",
    "de": "Alemán",
    "es": "Español",
    "fr": "Francés",
    "ja": "Japonés"
  },
  "hello": {
    "title": "Protege tu privacidad con un clic",
    "login": "Acceso",
    "register": "Crear cuenta Cropd"
  },
  "servers": {
    "search": "Buscar"
  },
  "drawer": {
    "vpn": "VPN",
    "settings": "Ajustes",
    "support": "Apoyo"
  },
  "theme": {
    "current": "Tema actual",
    "system": "Sistema",
    "dark": "Oscuro",
    "light": "Claro"
  },
  "paywall": {
    "title": "CropdAI Premium",
    "upgradeButton": "MEJORADO A PREMIUM",
    "titleModal": "Suscríbete a Premium ahora",
    "subtitleModal": "Acceso a todas las funciones premium",
    "subtitles": [
      "Absolutamente SIN ANUNCIOS",
      "Velocidad de conexión ultrarrápida",
      "Cifrado de grado militar",
      "Acceso a diferentes países",
      "Apoyo técnico",
      "Todas las próximas funciones"
    ],
    "awaiterText": "Esperando la respuesta del servidor de compras",
    "subscribeButton": "PRUEBA GRATUITA",
    "subscribeButtonNoTrial": "SUSCRÍBASE AHORA",
    "trial": "3 días de prueba gratis luego",
    "noTrial": "Suscripción auto renovable",
    "terms": "Condiciones de uso",
    "privacy": "Política de privacidad",
    "restore": "Restaurar compra"
  },
  "dialogs": {
    "wireguardInstall": {
      "title": "Instalar alguilón",
      "text": "CropdAI utiliza túneles de cliente oficiales de WireGuard® para asegurar su conexión.Presione el botón a continuación y permita que la instalación continúe.",
      "button": "Instalar"
    },
    "serviceInstall": {
      "title": "Configuración del servicio CropdAI",
      "text": "CropdAI utiliza el servicio de fondo para controlar su estado de conexión.Presione el botón a continuación y permita que la instalación continúe.",
      "button": "Configuración"
    },
    "serviceRun": {
      "title": "Ejecutar el servicio CropdAI",
      "text": "El servicio de fondo de CropdAI no se está ejecutando.Presione el botón a continuación para continuar.",
      "button": "Comenzar"
    },
    "serviceRemove": {
      "title": "Eliminar el servicio CropdAI",
      "text": "El servicio de fondo CropdAI debe instalarse y ejecutar para controlar su estado de conexión. Presione el botón de abajo para eliminarlo de todos modos.",
      "button": "Eliminar"
    },
    "tunnelRemove": {
      "title": "Retire el túnel CropdAI WireGuard",
      "text": "El túnel CropdAI WireGuard controla su conexión.Presione el botón de abajo para detenerlo y eliminarlo de todos modos.",
      "button": "Retirar túnel"
    },
    "accountExitDialog": {
      "title": "Cuenta de salida",
      "text": "¿Estás seguro de que quieres salir de tu cuenta de Cropd?",
      "mainButton": "Salida",
      "cancelButton": "Cancelar"
    },
    "updateDialog": {
      "title": "Actualización disponible",
      "text": "La actualización de CropdAI está lista para instalar.Reinicie la aplicación para continuar.",
      "mainButton": "Reanudar",
      "cancelButton": "Más tarde"
    }
  },
  "vpn": {
    "title": "Ubicación Preferida",
    "buttonConnect": "CONECTAR",
    "buttonDisconnect": "DESCONECTAR",
    "freeBadge": "GRATIS",
    "premiumBannerLine1": "3 días gratis de CropdAI Premium",
    "premiumBannerLine2": "Sin anuncios y conexión más rápida",
    "securedConnection": "Conexión rápida",
    "insecureConnection": "Conexión insegura",
    "VPNStates": [
      "Desconectado",
      "Conectando",
      "Conectado",
      "Desconectando",
      "Error"
    ]
  },
  "support": {
    "title": "Controles y soporte",
    "wgReinstall": "Instalar alguilón®",
    "wgReinstallDesc": "Diálogo de Configuración de Sableguard de ABRIR",
    "wgReinstallButton": "Abierto",
    "wgReinstallButtonInstalled": "Instalada",
    "wgReinstallButtonNotInstalled": "No instalado",
    "svcReinstall": "Instalar el servicio CropdAI",
    "svcReinstallDesc": "Abra el cuadro de diálogo Configuración del servicio de CropdAI",
    "svcReinstallButton": "Abierto",
    "svcReinstallButtonInstalled": "Instalada",
    "svcReinstallButtonNotInstalled": "No instalado",
    "svcRun": "Run CropdAI Service",
    "svcRunDesc": "Diálogo de servicio de reinicio de reinicio de reinicio",
    "svcRunButton": "Abierto",
    "svcRunButtonRunning": "Correr",
    "svcRunButtonStopped": "Interrumpida",
    "svcRemove": "Eliminar el servicio CropdAI",
    "svcRemoveDesc": "Abra el servicio CropdAI Eliminar el diálogo",
    "svcRemoveButton": "Eliminar",
    "wgTunnelRemove": "Túnel de Stop WireGuard",
    "wgTunnelRemoveDesc": "Hazlo en caso de problemas de conexión",
    "wgTunnelRemoveButton": "Eliminar",
    "wgTunnelRemoveButtonRunning": "Correr",
    "wgTunnelRemoveButtonStopped": "Interrumpida"
  },
  "settings": {
    "title": "Ajustes",
    "language": "Idioma",
    "theme": "Tema",
    "themeDesc": "Cambiar la apariencia de la aplicación",
    "account": "Cuenta Cropd",
    "accountControl": "Control de cuenta",
    "accountControlDesc": "Página de control de cuenta abierta",
    "accountControlButton": "Abierto",
    "accountLogin": "Iniciar sesión",
    "accountLogout": "Cerrar sesión",
    "accountRemove": "Borrar cuenta",
    "subsControl": "Control de suscripciones",
    "startWithSystem": "Comience con el sistema",
    "startWithSystemDesc": "Iniciar CropdAI con inicio del sistema",
    "startHidden": "Empiece escondido",
    "startHiddenDesc": "Comenzar a minimizar CropdAI",
    "tracking": "Enviar datos de uso anónimo",
    "trackingDesc": "Permítanos mejorar nuestra aplicación"
  },
  "account": {
    "title": "Cuenta Cropd",
    "errors": {
      "invalid_password": "Correo o contraseña incorrectos. Intentar otra vez",
      "invalid_code": "Código de inicio de sesión incorrecto. Intentar otra vez",
      "get_profile_error": "Correo o contraseña incorrectos. Intentar otra vez",
      "emptyEmail": "¡El email no podía estar vacío!",
      "incorrectEmail": "¡Dirección de E-Mail incorrecta!",
      "emptyPass": "¡La contraseña no puede estar vacía!",
      "emptyCode": "¡El código no podía estar vacío!"
    },
    "login": {
      "title": "Iniciar sesión",
      "createPre": "¿Nuevo usuario?",
      "createLink": "Crear cuenta Cropd",
      "emailLabel": "Correo electrónico",
      "emailPlaceholder": "john.doe@gmail.com",
      "passLabel": "Contraseña",
      "passPlaceholder": "***************",
      "mainBtn": "Continuar",
      "resetLink": "¿Olvidaste tu contraseña?",
      "otcBtn": "Envíame un código"
    },
    "register": {
      "title": "Registro",
      "loginPre": "¿Ya tienes una cuenta?",
      "loginLink": "Iniciar sesión",
      "emailLabel": "Correo electrónico",
      "emailPlaceholder": "john.doe@gmail.com",
      "passLabel": "Contraseña",
      "passPlaceholder": "***************",
      "mainBtn": "Continuar"
    },
    "resetPass": {
      "title": "Restablecer la contraseña",
      "createPre": "¿Nuevo usuario?",
      "createLink": "Crear cuenta Cropd",
      "emailLabel": "Correo electrónico",
      "emailPlaceholder": "john.doe@gmail.com",
      "mainBtn": "Enviar código",
      "resetLink": "¿Ya tienes el código de reinicio?",
      "otcBtn": "Envíame un código"
    },
    "updatePass": {
      "title": "Actualiza contraseña",
      "createPre": "¿Nuevo usuario?",
      "createLink": "Crear cuenta Cropd",
      "codeLabel": "Reset Code",
      "codePlaceholder": "Restablecer Código",
      "passLabel": "Nueva contraseña",
      "passPlaceholder": "***************",
      "mainBtn": "Continuar",
      "resetLink": "¿No recibe el código de reinicio?",
      "otcBtn": "Envíame un código"
    },
    "loginSendCode": {
      "title": "Iniciar sesión con código",
      "createPre": "¿Nuevo usuario?",
      "createLink": "Crear cuenta Cropd",
      "emailLabel": "Correo electrónico",
      "emailPlaceholder": "john.doe@gmail.com",
      "mainBtn": "Continuar",
      "otcLink": "¿Ya tiene el código de inicio de sesión?",
      "loginBtn": "Iniciar sesión con contraseña"
    },
    "loginCheckCode": {
      "title": "Iniciar sesión con código",
      "createPre": "¿Nuevo usuario?",
      "createLink": "Crear cuenta Cropd",
      "emailLabel": "Correo electrónico",
      "emailPlaceholder": "john.doe@gmail.com",
      "codeLabel": "Código de acceso",
      "codePlaceholder": "Código de inicio de sesión",
      "mainBtn": "Continuar",
      "otcLink": "¿No obtiene el código de inicio de sesión?",
      "loginBtn": "Iniciar sesión con contraseña"
    },
    "remove": {
      "title": "Eliminar cuenta",
      "message": "¿Estás seguro de que realmente quieres eliminar tu cuenta de Cropd de forma permanente?\n¡Esta acción será irreversible!\nEs posible que deba cancelar manualmente su suscripción después de la eliminación.",
      "cancelBtn": "Cancelar",
      "removeBtn": "Envíeme un correo electrónico Eliminar enlace"
    }
  },
  "location": {
    "region": {
      "Dev": "Dev",
      "Auto": "Auto",
      "Favorites": "Favoritas",
      "Latest": "Últimos",
      "Free": "Gratis",
      "World": "Mundo",
      "Americas": "América",
      "Europe": "Europa",
      "Asia": "Asia",
      "AsiaPacific": "Asia-Pacífico",
      "Australia": "Australia"
    },
    "country": {
      "CA": "Canadá",
      "MX": "México",
      "SE": "Suecia",
      "US": "Estados Unidos",
      "NL": "Países Bajos",
      "DE": "Alemania",
      "GB": "Reino Unido",
      "FR": "Francia",
      "IN": "India",
      "KR": "Corea del Sur",
      "SG": "Republica de Singapur",
      "JP": "Japón",
      "AU": "Australia",
      "RU": "Rusia",
      "FI": "Finlandia",
      "HK": "RAE de Hong Kong",
      "CH": "Switzerland",
      "ES": "Spain",
      "PT": "Portugal",
      "MD": "Moldova",
      "IE": "Ireland",
      "RO": "Romania",
      "HU": "Hungary",
      "CZ": "Czech Republic",
      "LV": "Latvia",
      "UA": "Ukraine",
      "IL": "Israel",
      "TR": "Turkey",
      "PL": "Poland",
      "BG": "Bulgaria",
      "KZ": "Kazakhstan",
      "RS": "Serbia",
      "SK": "Slovakia",
      "IT": "Italy",
      "GR": "Grecia"
    },
    "city": {
      "dev": "Dev MSK",
      "auto_fastest": "Ubicación más rápida",
      "auto_nearest": "País más cercano",
      "fr_paris_free": "París",
      "us_dallas_free": "Dallas",
      "de_frankfurt_free": "Frankfurt",
      "us_ny_free": "Nueva York",
      "ru_moscow_free": "Moscú",
      "auto": "Auto",
      "ca_toronto": "Toronto",
      "se_stockholm": "Estocolmo",
      "mx_mexico": "Ciudad de México",
      "us_atlanta": "Atlanta",
      "us_chicago": "Chicago",
      "us_dallas": "Dallas",
      "us_la": "Los Angeles",
      "us_miami": "Miami",
      "us_ny": "Nueva York",
      "us_sf": "San Francisco",
      "us_seattle": "Seattle",
      "us_sv": "Silicon Valley",
      "nl_amsterdam": "Amsterdam",
      "de_frankfurt": "Frankfurt",
      "gb_london": "Londres",
      "fr_paris": "París",
      "in_bengaluru": "Bangalore",
      "kr_seoul": "Seúl",
      "sg_singapore": "Singapur",
      "jp_tokio": "Tokio",
      "au_sydney": "Sydney",
      "ru_moscow": "Moscú",
      "gb_coventry": "Coventry",
      "fi_helsinki": "Helsinki",
      "us_secaucus": "Secaucus",
      "hk_hongkong": "Hong Kong",
      "nl_meppel": "Meppel",
      "ch_zug": "Zug",
      "es_madrid": "Madrid",
      "pt_lisbon": "Lisbon",
      "md_chisinau": "Chisinau",
      "ie_dublin": "Dublin",
      "ro_bucharest": "Bucharest",
      "hu_budapest": "Budapest",
      "cz_veseli": "Veseli nad Luznici",
      "lv_riga": "Riga",
      "ua_kyiv": "Kyiv",
      "il_tel_aviv": "Tel Aviv-Yafo",
      "tr_izmir": "Izmir",
      "pl_warsaw": "Warsaw",
      "bg_sofia": "Sofia",
      "kz_almaty": "Almaty",
      "rs_belgrade": "Belgrade",
      "sk_bratislava": "Bratislava",
      "it_rome": "Rome",
      "gr_thessaloniki": "Tesalónica",
      "us_secaucus_free": "Secaucus",
      "ca_beauharnois": "Beauharnois",
      "us_ashburn": "Ashburn",
    }
  }
}