import { atom, selector } from 'recoil';
import localforage from 'localforage'


let ws = null
export const getWS = () => {
  return ws
}
export const setWS = socket => {
  ws = socket
}
let sendMessage = null
export const getSendMessage = () => {
  return sendMessage
}
export const setSendMessage = f => {
  sendMessage = f
}

let onNewMessageHandler = null
export const getOnNewMessageHandler = () => {
  return onNewMessageHandler
}
export const setOnNewMessageHandler = handler => {
  onNewMessageHandler = handler
}

let onUpdateMessageHandler = null
export const getOnUpdateMessageHandler = () => {
  return onUpdateMessageHandler
}
export const setOnUpdateMessageHandler = handler => {
  onUpdateMessageHandler = handler
}

let onChatChunkHandler = null
export const getOnChatChunkHandler = () => {
  return onChatChunkHandler
}
export const setOnChatChunkHandler = handler => {
  onChatChunkHandler = handler
}
let db = null
export const getDb = () => {
  return db
}
export const setDb = handler => {
  db = handler
}

let chunks = {}
export const getChunks = () => {
  return chunks
}
export const setChunks = handler => {
  chunks = handler
}

export const chunksState = atom({
  key: 'chunksState',
  default: {},
})
export const typingState = atom({
  key: 'typingState',
  default: {},
})
export const lastMessageIdState = atom({
  key: 'lastMessageIdState',
  default: null,
})
export const subscribedState = atom({
  key: 'subscribedState',
  default: null,
})
export const balanceState = atom({
  key: 'balanceState',
  default: null,
})
export const isWsConnectedState = atom({
  key: 'isWsConnectedState',
  default: false,
})
export const serverSettingsState = atom({
  key: 'serverSettingsState',
  default: null,
})


export const topUpAmountState = atom({
  key: 'topUpAmountState',
  default: 100
});

export const accountBalanceState = atom({
  key: 'accountBalanceState',
  default: null,
});

// ***********************************
// appearance
// ***********************************
export const configsState = atom({
  key: 'configsState',
  default: null,
});

export const activeChatState = atom({
  key: 'activeChatState',
  default: null,
});

export const latestChatState = atom({
  key: 'latestChatState',
  default: null,
});

// ***********************************
// auth states
// ***********************************
export const tokenState = atom({
  key: 'tokenState',
  default: null,
});
export const accountEmailState = atom({
  key: 'accountEmailState',
  default: '',
});

export const accountState = atom({
  key: 'accountState',
  default: null,
});

export const isWSAuthorizedState = atom({
  key: 'isWSAuthorizedState',
  default: false,
});
// ***********************************
// appearance
// ***********************************
export const appearanceState = atom({
  key: 'appearanceState',
  default: 'dark',
});
export const savedColorSchemeState = atom({
  key: 'savedColorSchemeState',
  default: 'system',
});

export const bgState = atom({
  key: 'bgState',
  default: 'default',
});

// other 

export const showAuthAwaiterState = atom({
  key: 'showAuthAwaiterState',
  default: false
});
export const partnerSessionState = atom({
  key: 'partnerSessionState',
  default: null
});
export const clickIdState = atom({
  key: 'clickIdState',
  default: ''
});

//

const localForageEffect = (key, defaults) => ({ setSelf, onSet }) => {
  setSelf(localforage.getItem(key).then(savedValue =>
    savedValue != null
      ? JSON.parse(savedValue)
      : defaults
  ));

  // Subscribe to state changes and persist them to localforage
  onSet((newValue, _, isReset) => {
    isReset
      ? localforage.removeItem(key)
      : localforage.setItem(key, JSON.stringify(newValue));
  });
};


export const isSubscribedSelector = selector({
  key: 'isSubscribedSelector',
  get: ({ get }) => {
    const account = get(accountState)
    if (account && account.isTester) {
      return true
    }

    if (account && account.subStatus && account.subStatus.status) {
      const { status, nextPaymentTime } = account.subStatus
      if (status === 'ACTIVE') return true
      else if (status === 'CANCELLED') {
        const nextPaymentTimeMs = new Date(nextPaymentTime).valueOf()
        if (nextPaymentTimeMs > Date.now()) return true
      }
    }
    return false
  },
});



export const visitorIdState = atom({
  key: 'visitorIdState',
  default: ''
});
export const initPlanIdState = atom({
  key: 'initPlanIdState',
  default: null
});
export const initEmailState = atom({
  key: 'initEmailState',
  default: ''
});
export const resetCodeState = atom({
  key: 'resetCodeState',
  default: ''
});
export const loginCodeState = atom({
  key: 'loginCodeState',
  default: ''
});

// referral
export const promoCodeState = atom({
  key: 'promoCodeState',
  default: ''
});
export const couponState = atom({
  key: 'couponState',
  default: ''
});
export const partnerCodeState = atom({
  key: 'partnerCodeState',
  default: ''
});
export const isPartnerCodeValidState = atom({
  key: 'isPartnerCodeValidState',
  default: false
});
export const customPlanMonthsState = atom({
  key: 'customPlanMonthsState',
  default: 6
});
export const modalShowState = atom({
  key: 'modalShowState',
  default: false
});
export const plansState = atom({
  key: 'plansState',
  default: null
});
export const selectedPlanIDState = atom({
  key: 'selectedPlanID',
  default: null
});
export const showPaddleLoaderState = atom({
  key: 'showPaddleLoaderState',
  default: false
});
