import Dexie from 'dexie';
import { getDb } from '.';

let db = null; // Инициализируем как null
const debug = false;

export function initDB(userId) {
  db = new Dexie(`app_${userId}`);

  db.version(1).stores({
    messages: 'mid, _id, createdAt, senderId, chatId',
  });

  return db
}

export function getDB() {
  return db;
}

// CREATE - Добавление нового сообщения
export async function addMessage(message) {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    const id = await db.messages.add(message);
    if (debug) console.log("Сообщение добавлено:", id);
    return id;
  } catch (error) {
    if (debug) console.log("Ошибка добавления сообщения:", error);
    throw error;
  }
}

// READ - Чтение сообщения по mid
export async function getMessage(mid) {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    const message = await db.messages.get(mid);
    if (debug) console.log("Сообщение найдено:", message);
    return message;
  } catch (error) {
    if (debug) console.log("Ошибка чтения сообщения:", error);
    return null;
  }
}

// READ - Чтение всех сообщений
export async function getAllMessages() {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    const messages = await db.messages.toArray();
    if (debug) console.log("Все сообщения:", messages);
    return messages;
  } catch (error) {
    if (debug) console.log("Ошибка чтения всех сообщений:", error);
    return [];
  }
}

// UPDATE - Обновление сообщения
export async function updateMessage(message) {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    const updatedCount = await db.messages.put(message); // put обновляет, если запись существует, иначе добавляет
    if (debug) console.log("Сообщение обновлено, обновлено записей:", updatedCount);
    return updatedCount;
  } catch (error) {
    if (debug) console.log("Ошибка обновления сообщения:", error);
    throw error;
  }
}

// DELETE - Удаление сообщения по mid
export async function deleteMessage(mid) {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    await db.messages.delete(mid);
    if (debug) console.log("Сообщение удалено:", mid);
  } catch (error) {
    if (debug) console.log("Ошибка удаления сообщения:", error);
    throw error;
  }
}

export async function getLastMessageId() {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    const lastMessages = await db.messages
      .orderBy('createdAt')
      .reverse()
      .limit(10)
      .toArray();

    let lastMsgId = null;
    for (const msg of lastMessages) {
      if (msg._id) {
        lastMsgId = msg._id;
        break;
      }
    }
    if (debug) console.log("Последний ID сообщения:", lastMsgId);
    return lastMsgId;
  } catch (error) {
    if (debug) console.log("Ошибка при получении последнего ID сообщения:", error);
    return null;
  }
}

export async function getFirstMessageId() {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    const firstMessages = await db.messages
      .orderBy('createdAt')
      .limit(10)
      .toArray();

    let firstMsgId = null;
    for (const msg of firstMessages) {
      if (msg._id) {
        firstMsgId = msg._id;
        break;
      }
    }
    if (debug) console.log("Первый ID сообщения:", firstMsgId);
    return firstMsgId;
  } catch (error) {
    if (debug) console.log("Ошибка при получении первого ID сообщения:", error);
    return null;
  }
}

export async function getLatestMessages() {
  if (!db) {
    if (debug) console.log('DB is not initialized');
    return;
  }
  try {
    const uniqueChatIds = await db.messages
      .orderBy('chatId')
      .uniqueKeys()

    if (debug) console.log("uniqueChatIds", uniqueChatIds);
    const latestMessagesPromises = uniqueChatIds.map(chatId => {
      return db.messages
        .where('chatId')
        .equals(chatId)
        .reverse()
        .sortBy('createdAt')
    });

    const latest = await Promise.all(latestMessagesPromises);
    if (debug) console.log("latestMessages", latest);

    const latestMessages = latest.map(msgArr => {
      return {
        chatId: msgArr[0].chatId,
        message: msgArr[0]
      }
    });
    latestMessages.sort((a, b) => b.createdAt - a.createdAt);

    return latestMessages;
  } catch (error) {
    if (debug) console.log("Ошибка при получении первого ID сообщения:", error);
    return null;
  }
}
