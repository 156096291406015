
import React, { useState, useEffect, useMemo } from 'react';
import './App.css';
import { Container, CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Box } from '@mui/material';
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import localforage from 'localforage'


import Router from './Router';
import * as requests from '#src/requests'

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  appearanceState,
  visitorIdState,
  accountEmailState,
  initEmailState,
  resetCodeState,
  loginCodeState,
  showAuthAwaiterState,
  accountState,
  tokenState,
  clickIdState,
  initPlanIdState,
  modalShowState,
  plansState,
  selectedPlanIDState,
  showPaddleLoaderState,
  promoCodeState,
  couponState,
  partnerCodeState,
  isSubscribedSelector,
  isPartnerCodeValidState,
  partnerSessionState,

} from '#state'

function getBestMatchLang(locales) {
  // console.log('navigator.languages', navigator.languages)
  const userLangs = navigator.languages.map(lang => lang.slice(0, 2))
  const localesList = locales //['en', 'ru'] // Object.keys(locales)
  let bestMatchLang = 'en'
  for (const lang of userLangs) {
    if (localesList.includes(lang)) {
      bestMatchLang = lang
      break
    }
  }
  return bestMatchLang
}

const {
  mainDefault,
  serverRegionsDefault,
  localesDefault
} = require('./configDefault')

const bestMatchLang = getBestMatchLang(mainDefault.languages)
const localesList = mainDefault.languages //Object.keys(localesDefault)
const resources = {}
for (const lang of localesList) {
  resources[lang] = { translation: localesDefault[lang] }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // returnObjects: true,
    lng: bestMatchLang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })


function App() {


  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const systemAppearance = prefersDarkMode ? 'dark' : 'light'
  const [appearance, setAppearance] = useRecoilState(appearanceState)
  const [visitorId, setVisitorId] = useRecoilState(visitorIdState)
  const [clickId, setClickId] = useRecoilState(clickIdState)
  const [initPlanId, setInitPlanId] = useRecoilState(initPlanIdState)
  const [email, setEmail] = useRecoilState(accountEmailState)
  const [showAuthAwaiter, setShowAuthAwaiter] = useRecoilState(showAuthAwaiterState)
  const [token, setToken] = useRecoilState(tokenState)
  const [account, setAccount] = useRecoilState(accountState)
  const [initEmail, setInitEmail] = useRecoilState(initEmailState)
  const [resetCode, setResetCode] = useRecoilState(resetCodeState)
  const [loginCode, setLoginCode] = useRecoilState(loginCodeState)
  const [promoCode, setPromoCode] = useRecoilState(promoCodeState)
  const [coupon, setCoupon] = useRecoilState(couponState)
  // 
  const [modalShow, setModalShow] = useRecoilState(modalShowState)
  const [plans, setPlans] = useRecoilState(plansState)
  const [selectedPlanID, setSelectedPlanID] = useRecoilState(selectedPlanIDState)
  const [showPaddleLoader, setShowPaddleLoader] = useRecoilState(showPaddleLoaderState)
  
  const isSubscribed = useRecoilValue(isSubscribedSelector)
  
  const [partnerCode, setPartnerCode] = useRecoilState(partnerCodeState)
  const [partnerValid, setPartnerValid] = useRecoilState(isPartnerCodeValidState)
  const [partnerSession, setPartnerSession] = useRecoilState(partnerSessionState)

  // const isSubscribed = isSubscribedCheck(account)
  // const showPurchaseInfo = !isSubscribed && location.pathname === '/'


  return (
    <Box className="App">
      <Router />
    </Box>
  );
}

export default App;
