import React, { useEffect, useState, useRef } from 'react'
import { Box, Container, Typography, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import TextareaAutosize from 'react-textarea-autosize';
import localforage from 'localforage'
import { nanoid } from 'nanoid';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getChunks,
  setOnChatChunkHandler,
  lastMessageIdState,
  getSendMessage,
  accountState,
  configsState,
  activeChatState,
  latestChatState,
  typingState,
  chunksState,
} from '../state'

import { useLiveQuery } from "dexie-react-hooks";
import { initDB, getDB, addMessage, getLatestMessages } from '../state/db'

import FastRegister from '../components/FastRegister'
import * as configDefault from '../configDefault'
import * as requests from '#src/requests'

const accentColor = '#3ca994'
const accentColor2 = '#3ca9948a'

export function App() {
  const [configs, setConfigs] = useRecoilState(configsState)
  const [account, setAccount] = useRecoilState(accountState)
  const setChunks = useSetRecoilState(chunksState)

  useEffect(() => {
    const chunkHandler = (chunks) => {
      setChunks({ ...chunks })
    }
    setOnChatChunkHandler(chunkHandler)
  }, [])


  useEffect(() => {
    const run = async () => {
      if (account) {
        console.log('account', account)
        try {
          // const opened = await openDB(`app-${account.id}`, 1)
          await initDB(account.id)
        } catch (err) {
          console.log('openDB error', err)
        }
      } else {
        console.log('account not found', account)
      }
    }
    run()
  }, [account])

  useEffect(() => {
    const run = async () => {
      const configRes = await requests.getConfig({
        platform: 'web',
      })
      console.log('getConfig', configRes)
      if (configRes) {
        setConfigs(configRes)
      }
    }

    if (!configs) {
      setConfigs(configDefault)
      run()
    }
  }, [configs, setConfigs])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   })
  // }, [])

  return (
    <>
      <Box sx={{
        height: '76vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(5px)',
        p: { xs: 0, md: 2, lg: 3 },
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          height: '100%',
          minWidth: { xs: '12rem', md: '20rem', lg: '20rem' },
        }}>
          <ChatsList />
          <DialogWrapper />
        </Box>
      </Box>
    </>
  )
}

function DialogWrapper() {
  const [account, setAccount] = useRecoilState(accountState)
  const [configs, setConfigs] = useRecoilState(configsState)
  const [activeChat, setActiveChat] = useRecoilState(activeChatState)
  const [latestChat, setLatestChat] = useRecoilState(latestChatState)
  const [chatName, setChatName] = useState('Select a chat')

  useEffect(() => {
    if (configs && activeChat) {
      setChatName(configs.bots[activeChat].fullName || configs.bots[activeChat].name)
    }
  }, [configs, activeChat])

  const messages = useLiveQuery(async () => {
    const db = getDB()
    if (!db) return null

    return await db.messages
      .where('chatId')
      .equals(activeChat)
      .sortBy('createdAt')
    // .toArray();
  }, [activeChat, account], null);

  if (!account) {
    return (
      <DialogAuth chatName={chatName} />
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      flexBasis: '83%',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(5px)',
      gap: '0.5vmin',
    }}>
      <ChatHeader chatName={chatName} />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        flexBasis: '90%',
        position: 'relative',
      }}>

        {messages === null ? <Typography
          fontWeight={700}
          fontSize={16}
          letterSpacing={-0.32}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          Loading messages...
        </Typography> : null}

        {messages !== null && !messages?.length ? <Typography
          fontWeight={700}
          fontSize={16}
          letterSpacing={-0.32}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          You do not have any messages yet
        </Typography> : null}

        {messages?.map((message, i) => (
          <ChatMessage key={`${message._id}_${i}`} message={message} account={account} />
        ))}
        <MessageChunks activeChat={activeChat} account={account} />
        <TypingIndicator activeChat={activeChat} chatName={chatName} />
      </Box>

      <ChatInput />
    </Box>
  )
}

function ChatHeader(props) {
  const { chatName } = props
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      height: '2.5rem',
      flexBasis: '2.5rem',
      // px: 4,
    }}>
      <Typography
        fontWeight={700}
        fontSize={16}
        letterSpacing={-0.32}
        sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
      >
        {chatName}
      </Typography>
    </Box>
  )
}

function ChatInput() {
  const [account, setAccount] = useRecoilState(accountState)
  const [activeChat, setActiveChat] = useRecoilState(activeChatState)
  const [inputText, setInputText] = useState('')
  const draftToRef = useRef(null)

  const getMessageParams = () => {
    return {
      activeChat,
      inputText
    }
  }

  const send = async () => {
    const { activeChat, inputText } = getMessageParams()
    console.log('send', activeChat, inputText)
    if (inputText) {
      setInputText('')
      try {
        const sendMessage = getSendMessage()
        const mid = nanoid()
        const now = Date.now()
        addMessage({
          _id: mid,
          mid: mid,
          senderId: account.id,
          chatId: activeChat,
          createdAt: now,
          text: inputText,
          image: null,
          audio: null,
          video: null,
        })
        const command = {
          reqId: nanoid(),
          action: 'chatmsg', // 'message', // for assistant way
          preferHQ: false,
          message: {
            id: mid,
            createdAt: now,
            text: inputText,
            senderId: account.id,
            chatId: activeChat,
          },
        }
        sendMessage(command)
        await localforage.removeItem(`draft-${activeChat}`)
      } catch (err) {
        console.log(`clear chat draft-${activeChat}`, err)
      }
    }
  }

  useEffect(() => {
    const handler = (event) => {
      if (event.ctrlKey && event.key === "Enter") {
        console.log("Ctrl+Enter key pressed")
        send()
      }
    }
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    }
  }, [account, send])

  useEffect(() => {
    const run = async () => {
      const draft = await localforage.getItem(`draft-${activeChat}`)
      if (draft) {
        setInputText(draft)
      } else {
        setInputText('')
      }
    }
    if (activeChat) {
      run()
    }
  }, [activeChat])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      flexBasis: '5rem',
      gap: '0.5vmin',
      // px: 4,
    }}>
      <TextareaAutosize
        cacheMeasurements
        style={{
          flexBasis: '90%',
          flexGrow: 1,
          fontSize: 16,
          padding: '0.5rem',
          outline: 'none',
          border: 'none',
          backgroundColor: 'transparent',
          color: '#fff',
          resize: 'none',
        }}
        minRows={2}
        maxRows={6}
        value={inputText}
        placeholder="Type a message"
        onChange={ev => {
          const text = ev.target.value
          setInputText(text)
          clearTimeout(draftToRef.current)
          // console.log(`text updated draft-${activeChat}`)
          draftToRef.current = setTimeout(async () => {
            try {
              await localforage.setItem(`draft-${activeChat}`, text)
              // console.log(`saved chat draft-${activeChat}`)
            } catch (err) {
              console.log(`save chat draft-${activeChat} error`, err)
            }
          }, 500);
        }
        }
      />
      <Box sx={{
        flexBasis: '5%',
        height: '100%',
        p: 1
      }}>
        <Button
          disabled={!inputText}
          variant="contained"
          endIcon={<SendIcon />}
          onClick={send}
          sx={{
            width: '100%',
            height: '100%',
            fontSize: 16,
            backgroundColor: accentColor,
            color: '#fff',
          }}
        >
          Send
        </Button>
      </Box>
    </Box>
  )
}

function ChatMessage(props) {
  const { message, account } = props

  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      alignSelf: account.id === message.senderId ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: '8rem',
      maxWidth: '70%',
      px: 2,
      pt: 1,
      pb: 3,
      mx: 2,
      my: 1,
      backgroundColor: account.id === message.senderId ? accentColor2 : '#0000008a',
      borderRadius: '1vmin',
    }}>
      {message.image ? <img src={message.image} alt="message" style={{ width: '100%', maxWidth: '512px', height: 'auto' }} /> : null}
      <Typography
        fontWeight={400}
        fontSize={14}
        letterSpacing={-0.32}
        sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
      >
        {message.text}
      </Typography>

      <Typography
        fontWeight={400}
        fontSize={11}
        letterSpacing={-0.32}
        sx={{
          position: 'absolute',
          bottom: 2,
          right: '0.5vw',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
        }}
      >
        {getRelativeTimeString(message.createdAt)}
      </Typography>
    </Box>
  )
}

function MessageChunks(props) {
  const { activeChat, account } = props
  const chunks = useRecoilValue(chunksState)
  const text = chunks[activeChat]?.text
  if (!text) return null
  console.log('chunks', chunks)

  return (
    <ChatMessage message={{
      _id: 'chunk',
      mid: 'chunk',
      senderId: activeChat,
      chatId: activeChat,
      createdAt: Date.now(),
      text: text,
    }} account={account} />
  )
}

function TypingIndicator(props) {
  const { activeChat, chatName } = props
  const typing = useRecoilValue(typingState)

  const isTyping = typing[activeChat]
  if (!isTyping) return null

  return (
    <Box sx={{
      position: 'absolute',
      bottom: 4,
      left: 0,
      width: '100%',
      // px: 4,
    }}>
      <Typography
        fontWeight={400}
        fontSize={12}
        letterSpacing={-0.32}
        sx={{
          width: '100%',
          textAlign: 'center',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
        }}
      >
        {chatName} is typing...
      </Typography>
    </Box>
  )
}

function DialogAuth(props) {
  const { chatName } = props
  return (
    <Box sx={{
      display: 'flex',
      flexBasis: '83%',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(5px)',
      gap: '0.5vmin',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        height: '2.5rem',
        flexBasis: '2.5rem',
        // px: 4,
      }}>
        <Typography
          fontWeight={700}
          fontSize={16}
          letterSpacing={-0.32}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          {chatName}
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        flexBasis: '100%',
      }}>
        <Typography
          fontWeight={700}
          fontSize={16}
          letterSpacing={-0.32}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          Login or Register to chat
        </Typography>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
          mb: '5vh',
        }}>
          <FastRegister />
        </Box>
      </Box>
    </Box>
  )
}

function ChatsList() {
  const [configs, setConfigs] = useRecoilState(configsState)
  const [activeChat, setActiveChat] = useRecoilState(activeChatState)
  const [latestChat, setLatestChat] = useRecoilState(latestChatState)
  const lastMessageId = useRecoilValue(lastMessageIdState)
  const [account, setAccount] = useRecoilState(accountState)
  const [botsOrder, setBotsOrder] = useState([])

  const latestMessages = useLiveQuery(async () => {
    const db = getDB()
    if (!db) return []

    const latestMessages = await getLatestMessages()
    // console.log('latestMessages', latestMessages)
    return latestMessages
  }, [activeChat, lastMessageId, account], []);

  useEffect(() => {
    if (configs) {
      const botsInitialOrder = []
      for (const latest of latestMessages) {
        const bot = configs.bots[latest.chatId]
        if (bot) {
          const extBot = { ...bot, latestMessage: latest.message }
          botsInitialOrder.push(extBot)
        }
      }

      for (const interest of configs.interests) {
        // console.log('configs.interests', interest, configs.categories[interest.id])
        if (configs.categories[interest.id]) {
          for (const catBot of configs.categories[interest.id].bots) {
            if (configs.bots[catBot].subcategory) {
              for (const subCatBot of configs.bots[catBot].bots) {
                if (!botsInitialOrder.find(bot => bot.id === subCatBot)) {
                  botsInitialOrder.push(configs.bots[subCatBot])
                }
              }
            } else {
              if (!botsInitialOrder.find(bot => bot.id === catBot)) {
                botsInitialOrder.push(configs.bots[catBot])
              }
            }
          }
        } else {
          console.log('configs.interests not found', interest)
        }
      }
      // console.log('botsInitialOrder', botsInitialOrder)
      setBotsOrder(botsInitialOrder)
    }
  }, [configs, latestMessages])

  useEffect(() => {
    if (botsOrder.length && !activeChat) {
      setActiveChat(botsOrder[0].id)
    }
  }, [botsOrder, activeChat, setActiveChat])

  return (
    <Box sx={{
      display: 'flex',
      flexBasis: '17%',
      minWidth: { xs: '12rem', md: '20rem', lg: '20rem' },
      flexGrowth: 1,
      height: '100%',
      overflowY: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(5px)',
      p: { xs: 1, md: 2 },

    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        // px: 4,
      }}>
        {botsOrder.map((item, i) => {
          return (
            <ChatCard
              key={`${item.id}-${i}`}
              data={item}
            />
          )
        })}
      </Box>
    </Box>
  )
}


function ChatCard(props) {
  const { id, expert } = props.data
  const [activeChat, setActiveChat] = useRecoilState(activeChatState)

  if (expert) {
    return (
      <ExpertCard data={props.data} isActive={id === activeChat} setActiveChat={setActiveChat} />
    )
  } else {
    return (
      <PersonCard data={props.data} isActive={id === activeChat} setActiveChat={setActiveChat} />
    )
  }
}

function PersonCard(props) {
  const { isActive, data, setActiveChat } = props
  const { id, cardImage, name, fullName, cardDescription, latestMessage } = data

  const latestSpeakerId = latestMessage?.senderId
  const latestSpeaker = latestSpeakerId === id ? name : 'You'
  const secondaryText = latestMessage?.text ? `${latestSpeaker}: ${truncateMessage(latestMessage.text, 100)}` : truncateMessage(cardDescription, 100)

  return (
    <Box
      onClick={() => {
        // console.log('clicked', id)
        setActiveChat(id)
      }}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        py: 0.4,
        width: '100%',
        minHeight: { xs: '4rem', md: '4rem', lg: '4rem' },
        borderRadius: '1vmin',
        backgroundColor: isActive ? accentColor2 : 'transparent',
      }}>
      <Box sx={{
        // minWidth: '2.1vw',
        // height: '2.7vw',
        minWidth: '3.1rem',
        height: '100%',
        borderRadius: '1vmin',
        position: 'relative',
        overflow: 'hidden',
        flexShrink: 0,
        mr: '1vmin',
        ml: '0.3vmin',
        // backgroundImage: `url(${image})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
      }}>
        <Box sx={{
          width: '100%',
          minHeight: '100%',
          zIndex: 0,
          position: 'absolute',
        }}>
          <img
            src={cardImage}
            alt="card"
            style={{
              top: 0,
              left: 0,
              // position: 'absolute',
              width: '100%',
              minHeight: '100%',
              zIndex: 0,
            }}
          />
        </Box>

      </Box>

      <Box sx={{
        width: '100%',
        // height: '2.7vw',
        height: '100%',
        pr: 1,
        // zIndex: 10,
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        // p: '0.9vh',
        // pb: '1.5vh',
        // background: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0) 100%)',
        overflowY: 'hidden',
      }}>
        <Typography
          fontWeight={700}
          fontSize={15}
          letterSpacing={-0.32}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          {fullName}
        </Typography>

        <Typography
          fontWeight={400}
          fontSize={12}
          letterSpacing={-0.24}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          {secondaryText}
        </Typography>
      </Box>
    </Box>
  )
}

function ExpertCard(props) {
  const { isActive, data, setActiveChat } = props
  const { id, cardImage, name, cardDescription, latestMessage } = data

  const latestSpeakerId = latestMessage?.senderId
  const latestSpeaker = latestSpeakerId === id ? 'Bot' : 'You'
  const secondaryText = latestMessage?.text ? `${latestSpeaker}: ${truncateMessage(latestMessage.text, 100)}` : truncateMessage(cardDescription, 100)

  return (
    <Box
      onClick={() => {
        console.log('clicked', id)
        setActiveChat(id)
      }}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        py: 0.4,
        width: '100%',
        minHeight: { xs: '4rem', md: '4rem', lg: '4rem' },
        borderRadius: '1vmin',
        backgroundColor: isActive ? accentColor2 : 'transparent',
      }}>
      <Box sx={{
        // width: '2.1vw',
        // minHeight: '2.7vw',
        // width: '2.1vw',
        minWidth: '3.1rem',
        height: '100%',
        borderRadius: '1vmin',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        mr: '1vmin',
        ml: '0.3vmin',
        backgroundColor: '#000',
        // backgroundImage: `url(${image})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
      }}>
        <Box sx={{
          width: '1.5vw',
          minHeight: '1.5vw',
          // zIndex: 0,
          // position: 'absolute',
        }}>
          <img
            src={cardImage}
            alt="card"
            style={{
              top: 0,
              left: 0,
              width: '100%',
              minHeight: '100%',
              zIndex: 0,
            }}
          />
        </Box>

      </Box>

      <Box sx={{
        width: '100%',
        // height: '2.7vw',
        height: '100%',
        overflowY: 'hidden',
        pr: 1,
        // zIndex: 10,
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        // px: '0.5rem',
        // pb: '1.5vh',
        // background: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0) 100%)',
      }}>
        <Typography
          fontWeight={700}
          fontSize={15}
          letterSpacing={-0.32}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          {name}
        </Typography>

        <Typography
          fontWeight={400}
          fontSize={12}
          letterSpacing={-0.24}
          sx={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
        >
          {secondaryText}
        </Typography>
      </Box>
    </Box>
  )
}

function getRelativeTimeString(date, lang = navigator.language) {
  // Allow dates or times to be passed
  const timeMs = typeof date === "number" ? date : date.getTime();
  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);
  // Array reprsenting one minute, hour, day, week, month, etc in seconds
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];
  // Array equivalent to the above but in the string representation of the units
  const units = ["second", "minute", "hour", "day", "week", "month", "year"];
  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex(cutoff => cutoff > Math.abs(deltaSeconds));
  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;
  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" });
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}

function truncateMessage(message, maxLength) {
  if (message.length <= maxLength) {
    return message;
  }

  let truncatedMessage = message.substring(0, maxLength);

  // Ищем последний пробел в обрезанной строке
  const lastSpaceIndex = truncatedMessage.lastIndexOf(' ');

  if (lastSpaceIndex > 0) {
    // Обрезаем до последнего слова
    truncatedMessage = truncatedMessage.substring(0, lastSpaceIndex);
  }

  return truncatedMessage + '...';
}

export default App