import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link as RLink, useNavigate } from "react-router-dom";

import { Box, Typography, Link, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import * as requests from '#src/requests'

import TopUpAmount from '../components/TopUpAmount'
import BusinessOffer from '../components/BusinessOffer'
import FastRegister from '../components/FastRegister'
import AccountBalance from '../components/AccountBalance'

import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  tokenState,
  accountState,
  topUpAmountState,
} from '../state'

const tokenPrice = 0.001

export function TopUp() {
  const navigate = useNavigate();

  const [token, setToken] = useRecoilState(tokenState)
  const [account, setAccount] = useRecoilState(accountState)
  const [topUpAmount, setTopUpAmount] = useRecoilState(topUpAmountState)
  const [isAwaiting, setIsAwaiting] = useState(false)

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      }}
    >
      <Box sx={{
        position: 'relative',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}>
        <Box width={'100%'}>
          <Box mt={'1vh'} sx={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
            <Typography variant="h4" sx={{
              fontSize: '3.2vmin',
              zIndex: 1,
            }}>
              Top Up Your Account
            </Typography>
          </Box>
        </Box>

        <Box sx={{
          position: 'relative',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          width: '100%',
        }}>
          <Box mt={'2vh'} sx={{
            // width: '60%',
            minWidth: '30vw',
            px: '3vw',
            pb: '1vh',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backdropFilter: 'blur(30px)',
            boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}>

            <PurchaseStep>
              1. Create or login your Cropd Account
            </PurchaseStep>

            <FastRegister />

            {/*  */}

            <PurchaseStep>2. Choice token amount </PurchaseStep>

            <AccountBalance />

            <BusinessOffer />

            <TopUpAmount />

            <Typography variant="body" sx={{
              fontSize: '1vmin',
              zIndex: 1,
            }}>
              You will get {`${Math.floor(topUpAmount / tokenPrice)} tokens`}
            </Typography>

            {/*  */}

            <PurchaseStep>
              3. Complete your order
            </PurchaseStep>

            <Button
              disabled={!account || isAwaiting || topUpAmount < 10 || topUpAmount > 100000}
              sx={{
                mt: '1vh',
              }}
              variant="outlined"
              color="inherit"
              size='large'
              startIcon={isAwaiting ? <CircularProgress size={27} /> : null}
              onClick={async () => {
                // navigate('/app')
                setIsAwaiting(true)
                // console.log('Checkout', window.Checkout)

                try {
                  const result = await requests.createTransaction(token, topUpAmount)
                  console.log('createTransaction result', topUpAmount, result)

                  if (result?.success) {
                    window.Checkout.configure({
                      session: {
                        id: result.session.id,
                        version: result.session.version
                      }
                    });
                    window.Checkout.showPaymentPage();
                  }
                } catch (error) {
                  console.log('createTransaction error', error)
                } finally {
                  setIsAwaiting(false)
                }

                // setTimeout(() => {
                //   setIsAwaiting(false)
                // }, 2000);
              }}
            >
              {!isAwaiting ? 'Checkout' : null}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

function PurchaseStep({ children }) {
  return (
    <Box >
      <Typography
        variant="body1"
        color="text.primary"
        sx={{
          mt: 2,
          mb: 1,
          // pr: 0.5,
          display: 'inline-block',
          borderBottom: '1px solid',
          borderColor: 'text.primary',
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default TopUp