const locale = {
  languages: {
    en: 'English',
    ru: 'Russian',
    de: 'German',
    es: 'Spanish',
    fr: 'French',
    ja: 'Japanese'
  },
  hello: {
    title: 'Protect your privacy with a click',
    login: 'Log in',
    register: 'Create Cropd Account'
  },
  servers: {
    search: 'Search'
  },
  drawer: {
    vpn: 'VPN',
    settings: 'Settings',
    support: 'Support'
  },
  theme: {
    current: 'Current Theme',
    system: 'System',
    dark: 'Dark',
    light: 'Light'
  },
  paywall: {
    title: 'CropdAI Premium',
    upgradeButton: 'UPGRADE TO PREMIUM',
    titleModal: 'Subscribe to Premium now',
    subtitleModal: 'Get access to all premium features',
    subtitles: [
      'Absolutely NO ADS',
      'Blazing fast connection speed',
      'Military-grade encryption',
      'Unlock all virtual locations',
      'Tech support',
      'All upcoming features'
    ],
    awaiterText: 'Awaiting purchase server response',
    subscribeButton: 'START FREE TRIAL',
    subscribeButtonNoTrial: 'SUBSCRIBE NOW',
    trial: '3 days free trial then',
    noTrial: 'Auto-renewable subscription',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    restore: 'Restore Purchase'
  },
  settings: {
    title: 'Settings',
    language: 'Language',
    theme: 'Theme',
    themeDesc: 'Change the app appearance',
    account: 'Cropd Account',
    accountControl: 'Account Control',
    accountControlDesc: 'Open account control page',
    accountControlButton: 'Open',
    accountLogin: 'Log in',
    accountLogout: 'Log out',
    accountRemove: 'DELETE Account',
    subsControl: 'Subscriptions Control',
    startWithSystem: 'Start with System',
    startWithSystemDesc: 'Start CropdAI with System startup',
    startHidden: 'Start Hidden',
    startHiddenDesc: 'Start CropdAI minimized',
    tracking: 'Send anonymous usage data',
    trackingDesc: 'Allow us to improve our app'
  },
  account: {
    title: 'Cropd Account',
    "errors": {
      "invalid_password": "Incorrect email or password. Try again",
      "invalid_code": "Incorrect code. Try again later",
      "get_profile_error": "Incorrect email or password. Try again",
      "emptyEmail": "Email could not be empty!",
      "incorrectEmail": "Incorrect email address!",
      "emptyPass": "Password could not be empty!",
      "emptyCode": "Code could not be empty!",
      "invalid_promo_code": "Not valid promo code!",
    },
    login: {
      title: 'Log In',
      createPre: 'New user?',
      createLink: 'Create Cropd Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'john.doe@gmail.com',
      passLabel: 'Password',
      passPlaceholder: '***************',
      mainBtn: 'Continue',
      resetLink: 'Forgot your password?',
      otcBtn: 'Email me a login code'
    },
    register: {
      title: 'Register',
      loginPre: 'Already have an account?',
      loginLink: 'Log In',
      emailLabel: 'Email address',
      emailPlaceholder: 'john.doe@gmail.com',
      passLabel: 'Password',
      passPlaceholder: '***************',
      mainBtn: 'Continue'
    },
    resetPass: {
      title: 'Reset Password',
      createPre: 'New user?',
      createLink: 'Create Cropd Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'john.doe@gmail.com',
      mainBtn: 'Send Code',
      resetLink: 'Already have Reset Code?',
      otcBtn: 'Email me a login code'
    },
    updatePass: {
      title: 'Update Password',
      createPre: 'New user?',
      createLink: 'Create Cropd Account',
      codeLabel: 'Reset Code',
      codePlaceholder: '***************',
      passLabel: 'New Password',
      passPlaceholder: '***************',
      mainBtn: 'Continue',
      resetLink: 'Do not get Reset Code?',
      otcBtn: 'Email me a login code'
    },
    loginSendCode: {
      title: 'Log in with code',
      createPre: 'New user?',
      createLink: 'Create Cropd Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'john.doe@gmail.com',
      mainBtn: 'Continue',
      otcLink: 'Already have Login Code?',
      loginBtn: 'Log in by password'
    },
    loginCheckCode: {
      title: 'Log in with code',
      createPre: 'New user?',
      createLink: 'Create Cropd Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'john.doe@gmail.com',
      codeLabel: 'Login Code',
      codePlaceholder: '123456',
      mainBtn: 'Continue',
      otcLink: 'Do not get Login Code?',
      loginBtn: 'Log in by password'
    },
    remove: {
      title: 'Remove Account',
      message:
        'Are you sure that you really want to permanently delete your Cropd account?\nThis action will be irreversible!\nYou may need to manually cancel your subscription after deletion.',
      cancelBtn: 'Cancel',
      removeBtn: 'Email me remove link'
    }
  }
}

export default locale
